import { matchPath, useLocation } from 'react-router-dom';

import { routeConfig } from '~/routeConfig';

/** Checks if we are now in subscription path */
export const useIsCatalog = () => {
    const { pathname } = useLocation();

    return (
        Boolean(matchPath(routeConfig.SubscriptionCatalogue.path, pathname)) ||
        Boolean(matchPath(routeConfig.PurchaseCatalogue.path, pathname))
    );
};
