import { EParamKeys, TQuerySearchParams } from '~/types';

export const SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY = 'catalogue_search_params';
export const SESSION_STORAGE_SUBSCRIPTION_TERM_KEY = 'subscription_term';
export const COOKIES_UTM_TAGS_KEY = 'source_info';
export const SUPPORT_EMAIL = 'client@vtb-auto.ru';
export const SUPPORT_PHONE = '+78007005016';
export const SUPPORT_PHONE_LABEL = '+7 (800) 700 50 16';
export const MESSENGER_PHONE = '+79013319802';
export const TELEGRAM_URL = `https://t.me/${MESSENGER_PHONE}`;
export const WHATSAPP_URL = `https://wa.me/${MESSENGER_PHONE}`;
export const BASE_URL = 'https://vtb-auto.ru';
export const PER_PAGE_NUMBER = 15;
export const DEFAULT_FILTERS: TQuerySearchParams = {
  [EParamKeys.SortDirection]: undefined,
  [EParamKeys.Page]: '1',
  [EParamKeys.PerPage]: PER_PAGE_NUMBER.toString(),
  [EParamKeys.Tag]: undefined,
  [EParamKeys.Brands]: undefined,
  [EParamKeys.Models]: undefined,
} as const;
