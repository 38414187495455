import { NavLink } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import { css } from '@emotion/react';

import PurchaseBannerLg from 'assets/images/purchase-banner-lg.webp';
import PurchaseBannerMd from 'assets/images/purchase-banner-md.webp';
import PurchaseBannerSm from 'assets/images/purchase-banner-sm.webp';
import PurchaseBannerXl from 'assets/images/purchase-banner-xl.webp';
import SubscriptionBannerLg from 'assets/images/subscription-banner-lg.webp';
import SubscriptionBannerMd from 'assets/images/subscription-banner-md.webp';
import SubscriptionBannerSm from 'assets/images/subscription-banner-sm.webp';
import SubscriptionBannerXl from 'assets/images/subscription-banner-xl.webp';
import {styled} from "@mui/system";

export const Section = styled('section')<{
  isSubscription: boolean;
  isPurchase: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  height: 450px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 0 0 12px 12px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background-position: 100% 10%;
    height: 320px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    background-position: 100% 10%;
    height: 450px;
  }

  ${({ theme, isSubscription }) =>
    isSubscription &&
    `
      background-image: url(${SubscriptionBannerSm});

      ${theme.breakpoints.up('sm')} {
        border-radius: 0;
        background-image: url(${SubscriptionBannerMd});
      }

      ${theme.breakpoints.up('lg')} {
        background-image: url(${SubscriptionBannerLg});
      }
     
      ${theme.breakpoints.up('xl')} {
        background-image: url(${SubscriptionBannerXl});
      }
  `}

  ${({ theme, isPurchase }) =>
    isPurchase &&
    `
      background-image: url(${PurchaseBannerSm});

      ${theme.breakpoints.up('sm')} {
        border-radius: 0;
        background-image: url(${PurchaseBannerMd});
      }

      ${theme.breakpoints.up('lg')} {
        background-image: url(${PurchaseBannerLg});
      }
      
      ${theme.breakpoints.up('xl')} {
        background-image: url(${PurchaseBannerXl});
      }
  `}
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(16)};
    padding-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 500;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const LinkTitle = styled(Typography)`
  color: ${({theme}) => theme.palette.common.white};

  ${({theme}) => theme.breakpoints.up('md')} {
    margin-bottom: ${({theme}) => theme.spacing(0.5)};
  }
  
  ${({theme}) => theme.breakpoints.up('lg')} {
    margin-bottom: ${({theme}) => theme.spacing(1)};
  }
`;

export const StyledLink = styled(NavLink)<{
  left?: boolean;
  right?: boolean;
}>`
  display: block;
  padding: 12px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(2)};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  flex-grow: 1;
  height: 100%;

  ${({ left }) =>
    left &&
    css`
      border-radius: 12px 0 0 12px;
    `}
  ${({ right }) =>
    right &&
    css`
      border-radius: 0 12px 12px 0;
    `}
  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: inherit;
  }

  &.active {
    cursor: default;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
  }

  &.active {
    background-color: ${({ theme }) => theme.palette.common.white};

    > :last-of-type {
      color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
    }

    > :first-of-type {
      color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
    }
  }
`;
