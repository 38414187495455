import { useMemo } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton, Stack, Tooltip } from '@mui/material';

import { useBreakpoints, useCopyToClipboard } from '~/hooks';
import { ECarTagLabel, TCar } from '~/types';
import { getCarOptions, separateThousands } from '~/utils';

import { OldPrice, Options, Price } from '../../CarInfo.styles';
import { CarDescription } from '../CarDescription';
import { Vin } from './PurchaseInfo.styles';

export const PurchaseInfo = ({ car }: { car: TCar }) => {
  const copyToClipboard = useCopyToClipboard();
  const price = car.pokupkaPrice != car.fullPrice ? car.pokupkaPrice : car.fullPrice;
  const oldPrice = car.pokupkaPrice != car.fullPrice && car.fullPrice;
  const isUsed = car.owners_number > 0;
  const { isDesktop } = useBreakpoints();

  const carOptions = useMemo(
    () =>
      getCarOptions(
        isUsed
          ? [
              car.registry_year.toString(),
              car.mileage ? separateThousands(car.mileage) + ' км' : '',
              car.engine_type,
              car.power_hp + ' л.с.',
              car.engine + ' л.',
              car.gearbox,
              car.drive,
              car.color,
            ]
          : [
              car.registry_year.toString(),
              car.engine_type,
              car.power_hp + ' л.с.',
              car.engine + ' л.',
              car.gearbox,
              car.drive,
              car.color,
            ],
      ),
    [car],
  );

  const carDescription = useMemo(() => {
    if (isUsed) {
      return {
        items: [
          'Куплен и обслуживался у официального дилера',
          car.owners_number === 1 && 'Один владелец по ПТС',
          'Оригинальный пробег',
          'Гарантия юридической чистоты',
          'Специальные условия на кредит и страхование от ВТБ Авто',
        ],
      };
    }

    if (car.newTags.find(({ label }) => label === ECarTagLabel.DelayedPayment)) {
      return {
        header: (
          <div>
            Новые авто с отложенным
            <br />
            основным платежом &nbsp;
            <Tooltip title='Вы можете приобрести автомобиль со сниженными платежами в месяц и отложить основной на срок от 3 месяцев до 3 лет'>
              <IconButton size='small'>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>
        ),
        items: [
          'Вы найдете нужный авто: в наличии или под заказ',
          'Сниженные фиксированные цены, без скрытых платежей и комиссий',
          'Автокредитование на специальных условиях от ВТБ',
        ],
      };
    }

    if (car.newTags.find(({ label }) => label === ECarTagLabel.Foreign)) {
      return {
        items: [
          'Все автомобили растаможены и привезены в соответствии с требованиями законодательства Российской Федерации',
          'Никаких скрытых платежей и комиссий',
          'Простая и безопасная сделка',
          'Бесплатная доставка авто до дома ',
        ],
      };
    }

    return null;
  }, [car, isDesktop]);

  return (
    <Stack>
      <Options marginBottom={1}>{carOptions}</Options>

      {isUsed && (
        <Vin>
          <span>VIN: </span>
          <span>{car.vin}</span>
          <Tooltip title='Скопировать VIN'>
            <IconButton size='small' onClick={() => copyToClipboard(car.vin, 'VIN скопирован.')}>
              <ContentCopyIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Vin>
      )}

      <Stack rowGap={1} marginBottom={{ xs: 2, lg: 3 }}>
        {price && <Price variant='h1'>{`${separateThousands(price)} ₽`}</Price>}
        {oldPrice && <OldPrice variant='body2'>{`${separateThousands(oldPrice)} ₽`}</OldPrice>}
      </Stack>

      {carDescription && (
        <CarDescription header={carDescription?.header} items={carDescription?.items} />
      )}
    </Stack>
  );
};
