import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const IconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.vtbAuto.BluePrimary};
  fill: ${({ theme }) => theme.palette.common.white};
  width: 32px;
  height: 32px;

  & svg {
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 40px;
    height: 40px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;
