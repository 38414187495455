import { ThemeOptions } from '@mui/material';

import { css } from '@emotion/react';

export const textFieldOptions: ThemeOptions = {
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => css`
          color: ${theme.palette.vtbAuto.DarkGray};
          background: ${theme.palette.common.white};
        `,
      },
    },
  },
};
