import { PropsWithChildren } from 'react';

import { Container } from '@mui/material';

import { FloatingActionButton } from '~/components';

import { Footer, Header, ScrollToTop, UtmTagsProcessor } from './components';
import { Main } from './Layout.styles';

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header />
      <Main>
        <Container>{children}</Container>
        <Footer />
      </Main>

      <FloatingActionButton />
      <ScrollToTop />
      <UtmTagsProcessor />
    </>
  );
};
