import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import { CallbackForm, Documents, Faq, Seo } from '~/components';
import { CarCards } from '~/components';
import { SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY } from '~/consts';
import { useBreakpoints, useIsSubscription, useQueryStringParam } from '~/hooks';
import { useSearchCars } from '~/queries';
import { ECarTagLabel, EParamKeys } from '~/types';

import { Banner, Filters, NoCarCards, PurchaseTiles, SubscriptionTiles } from './components';

export const Catalogue: FC = () => {
  const isSubscription = useIsSubscription();
  const location = useLocation();
  const { isMobile, isDesktop } = useBreakpoints();
  const { data: searchCars, isValidating, isLoading, error } = useSearchCars();
  const selectedTag = useQueryStringParam(EParamKeys.Tag, '');

  useEffect(() => {
    // Сохраняем параметры поиска для кнопки "В каталог"
    location.search
      ? sessionStorage.setItem(SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY, location.search)
      : sessionStorage.removeItem(SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY);
  }, [location.search]);

  const formProps = isSubscription
    ? {
        title: (
          <>
            Оставьте заявку
            {isDesktop ? <br /> : ''} на авто по подписке
          </>
        ),
      }
    : {
        title: 'Оставьте заявку на консультацию',
        description:
          'Мы поможем определиться с выбором авто и расскажем, как его выгодно приобрести.',
      };

  const noResults = !isValidating && !error && searchCars && searchCars.data.length === 0;

  return (
    <Stack rowGap={{ xs: 2, sm: 3, lg: 4 }} mb={{ xs: 2, sm: 3, lg: 4 }}>
      <Seo />
      <Banner />
      {isSubscription ? <SubscriptionTiles /> : <PurchaseTiles />}
      <section>
        <Typography
          variant={isMobile ? 'h4' : 'h3'}
          color={(theme) => theme.palette.vtbAuto.DarkGray}
          mb={2}
        >
          Выберите авто
        </Typography>

        <Filters>
          {noResults ? (
            <NoCarCards />
          ) : (
            <CarCards
              cars={searchCars?.data}
              isLoading={isValidating || isLoading}
              showRequestCard
              tagToShow={selectedTag}
              showSelfTag={!selectedTag}
            />
          )}
        </Filters>
      </section>
      <CallbackForm {...formProps} />
      {/* Отображаем документы и FAQ для подписок и при выборе тега "Отложенный платеж" */}
      {(isSubscription || selectedTag === ECarTagLabel.DelayedPayment) && (
        <>
          <Documents />
          <Faq />
        </>
      )}
    </Stack>
  );
};
