import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeOptions } from '@mui/material';

export const selectOptions: ThemeOptions = {
  components: {
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
  },
};
