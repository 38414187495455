import { FC, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/material';

import { SESSION_STORAGE_SUBSCRIPTION_TERM_KEY } from '~/consts';
import { TCar } from '~/types';
import { getCarOptions, separateThousands } from '~/utils';

import { OldPrice, Options, Price } from '../../CarInfo.styles';
import { CarDescription } from '../CarDescription';
import { Run, SubscriptionButtonGroup, SubscriptionPeriodButton } from './SubscriptionInfo.styles';

type Props = {
  car: TCar;
};
export const SubscriptionInfo: FC<Props> = ({ car }) => {
  const [subscriptionPeriodIdx, setSubscriptionPeriodIdx] = useState(
    car.subscriptions.findIndex((item) => item.duration === car.subscription.duration),
  );
  const subscription = car.subscriptions[subscriptionPeriodIdx];

  useEffect(() => {
    // Сохраняем условия подписки в session storage для использования в ФОС
    sessionStorage.setItem(
      SESSION_STORAGE_SUBSCRIPTION_TERM_KEY,
      JSON.stringify({
        period: subscription.duration,
        price: subscription.price,
      }),
    );

    // Очищаем условия подписки при удалении компонента
    return () => sessionStorage.removeItem(SESSION_STORAGE_SUBSCRIPTION_TERM_KEY);
  }, [subscriptionPeriodIdx]);

  const carOptions = useMemo(() => {
    return getCarOptions([
      car.registry_year.toString(),
      car.engine_type,
      car.power_hp + ' л.с.',
      car.engine + ' л.',
      car.gearbox,
      car.drive,
      car.color,
    ]);
  }, [car]);

  let newPrice = subscription.price,
    oldPrice;

  if (subscription.price_spec > 0 && subscription.price_spec != subscription.price) {
    newPrice = subscription.price_spec;
    oldPrice = subscription.price;
  }

  return (
    <Stack>
      <Options marginBottom={{ xs: 1, lg: 3 }}>{carOptions}</Options>

      <SubscriptionButtonGroup
        size='medium'
        sx={{
          marginBottom: { xs: 1, lg: 3 },
        }}
      >
        {car.subscriptions.map(({ duration }, idx) => (
          <SubscriptionPeriodButton
            key={idx}
            variant={subscriptionPeriodIdx === idx ? 'contained' : 'text'}
            onClick={() => setSubscriptionPeriodIdx(idx)}
          >
            {`${separateThousands(duration)} мес`}
          </SubscriptionPeriodButton>
        ))}
      </SubscriptionButtonGroup>

      <Stack rowGap={1} marginBottom={{ xs: 2, lg: 3 }}>
        <Run>{`Включено ${separateThousands(subscription.run)} км пробега`}</Run>
        {newPrice && <Price>{`от ${separateThousands(newPrice)} ₽ / мес.`}</Price>}
        {oldPrice && (
          <OldPrice variant='h6'>{`от ${separateThousands(oldPrice)} ₽ / мес.`}</OldPrice>
        )}
      </Stack>

      <CarDescription
        header='Авто по подписке 🚘'
        items={[
          'Новый авто без первоначального взноса и крупных вложений',
          'Страхование, ТО, шинный сервис и налоги уже включены в подписку',
          'Консьерж-сервис с доставкой до дома',
          'Авто в наличии в парке ВТБ',
        ]}
      />
    </Stack>
  );
};
