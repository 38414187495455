import { Box, Grid, Stack } from "@mui/material";

import { ReactComponent as CreditSolid } from "assets/icons/credit-solid.svg";
import { ReactComponent as FinanceSolid } from "assets/icons/finance-solid.svg";
import { ReactComponent as CloseSolid } from "assets/icons/close-solid.svg";
import CarMoving from "assets/images/car-moving.webp";
import { useBreakpoints } from "~/hooks";

import {
  PriceBig,
  Title,
  Text,
  PriceSmall,
  PriceLabel,
  DescriptionLabel,
  IconWrapper,
  Card,
  TextLabel,
  TextSmall,
} from "./PriceCalculationExample.styles";

export const PriceCalculationExample = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const pinkColor = "#F5F1FF";
  const greenColor = "#DCFAEC";
  const darkGreenColor = "#BEE8D4";

  const blueColor = "#DFF4FF";
  const darkBlueColor = "#B7DAED";

  const lavenderColor = "#E6F1FF";

  const carGradient =
    "linear-gradient(270.00deg, rgba(241, 224, 255, 0.67) 28.657%,rgba(85, 120, 255, 0) 73.338%)";

  const purpleColor = "#9C27B080";

  return (
    <>
      {isDesktop && (
        <Card>
          <Title marginBottom="17px">
            Пример расчета стоимости Jetour Dashing
          </Title>
          <Grid container spacing="2px" columns={16}>
            {/* Блок со стоимостью */}
            <Grid
              sm={11}
              container
              item
              spacing="2px"
              alignItems="stretch"
              justifyContent="stretch"
              columns={16}
            >
              <Grid item sm={16}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    padding: "12px 25px",
                    backgroundColor: pinkColor,
                    borderRadius: "4px",
                  }}
                >
                  <Text>Полная стоимость</Text>

                  <Stack direction="column" alignItems="end">
                    <PriceSmall sx={{ textDecoration: "line-through" }}>
                      2 979 900 ₽
                    </PriceSmall>
                    <PriceBig>2 772 370 ₽</PriceBig>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item sm={2}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "8px",
                    backgroundColor: greenColor,
                    borderRadius: "4px",
                  }}
                >
                  <Text>Бронь</Text>
                  <Box
                    sx={{
                      width: "100%",
                      height: "22px",
                      backgroundColor: darkGreenColor,
                      borderRadius: "4px",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item sm={6}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "8px",
                    backgroundColor: blueColor,
                    borderRadius: "4px",
                  }}
                >
                  <Stack direction="column" rowGap="4px" paddingX="14px">
                    <Text>Аренда</Text>
                    <TextSmall>Три равных платежа в конце месяца</TextSmall>
                  </Stack>

                  <Grid container spacing="2px">
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "22px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "22px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "22px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>

              <Grid item sm={8}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "7px",
                    backgroundColor: lavenderColor,
                    borderRadius: "4px",
                  }}
                >
                  <Stack direction="column" rowGap="4px" paddingX="14px">
                    <Text>Авто переходит в собственность за</Text>
                    <PriceBig>2 415 770 ₽</PriceBig>
                    <TextSmall>Отложенный платеж</TextSmall>
                  </Stack>

                  <Stack direction="row" alignItems="end">
                    <Box
                      sx={{
                        height: 22,
                        background: carGradient,
                        width: "70%",
                      }}
                    />
                    <Box
                      src={CarMoving}
                      component="img"
                      sx={{
                        width: 89,
                        height: 29,
                        marginLeft: "-20px",
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* Способы выкупа авто (правый блок) */}
            <Grid sm={5} item>
              <Box
                sx={{
                  height: "100%",
                  padding: "0 32px",
                  backgroundColor: lavenderColor,
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack direction="column" rowGap="4px">
                  <TextLabel
                    color={(theme) =>
                      `${theme.palette.vtbAuto.BlueAccent}!important`
                    }
                  >
                    Способы выкупа авто
                  </TextLabel>

                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <FinanceSolid />
                    </IconWrapper>
                    <TextLabel>Автокредит</TextLabel>
                  </Stack>
                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <CreditSolid />
                    </IconWrapper>
                    <TextLabel>Покупка за собственные средства</TextLabel>
                  </Stack>
                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <CloseSolid fill={purpleColor} />
                    </IconWrapper>
                    <TextLabel>Возможность отказаться от покупки</TextLabel>
                  </Stack>
                </Stack>
              </Box>
            </Grid>

            <Grid sm={11} item container columns={16}>
              {/* Обозначения на шкале */}
              <Grid item sm={2}>
                <Stack direction="row" justifyContent="center">
                  <PriceLabel margin="auto">20 000 ₽</PriceLabel>
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <Stack direction="row" justifyContent="center">
                  <PriceLabel margin="auto">112 200 ₽/мес.</PriceLabel>
                </Stack>
              </Grid>
            </Grid>

            <Grid item sm={5}>
              <Stack direction="row" alignItems="bottom" justifyContent="end">
                <DescriptionLabel pale mt="4px">
                  Пример расчетов. Предложение не является офертой.
                  *Обеспечительный платеж
                </DescriptionLabel>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      )}

      {isTablet && (
        <Box
          borderTop={(theme) => `solid 1px ${theme.palette.vtbl.Silver}`}
          marginTop="8px"
        >
          <Title marginY="8px">Пример расчета стоимости Jetour Dashing</Title>
          <Grid container spacing="2px" columns={16}>
            {/* Блок со стоимостью */}
            <Grid
              sm={11}
              container
              item
              spacing="2px"
              alignItems="stretch"
              justifyContent="stretch"
              columns={16}
            >
              <Grid item sm={2}>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "11px",
                    backgroundColor: greenColor,
                    borderRadius: "4px",
                  }}
                >
                  <Text>Бронь</Text>
                  <Box
                    sx={{
                      width: "100%",
                      height: "18px",
                      backgroundColor: darkGreenColor,
                      borderRadius: "4px",
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item sm={6}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "11px",
                    backgroundColor: blueColor,
                    borderRadius: "4px",
                  }}
                >
                  <Stack direction="column" rowGap="4px" paddingX="9px">
                    <Text>Аренда</Text>
                    <TextSmall>Три равных платежа в конце месяца</TextSmall>
                  </Stack>

                  <Grid container spacing="2px">
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "18px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "18px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "18px",
                          backgroundColor: darkBlueColor,
                          borderRadius: "4px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>

              <Grid item sm={8}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{
                    height: "100%",
                    paddingTop: "11px",
                    backgroundColor: lavenderColor,
                    borderRadius: "4px",
                  }}
                >
                  <Stack direction="column" rowGap="4px" paddingX="9px">
                    <Text>Авто переходит в собственность за</Text>
                    <PriceBig>2 415 770 ₽</PriceBig>
                    <TextSmall>Отложенный платеж</TextSmall>
                  </Stack>

                  <Stack direction="row" alignItems="end">
                    <Box
                      sx={{
                        height: 22,
                        background: carGradient,
                        width: "70%",
                      }}
                    />
                    <Box
                      src={CarMoving}
                      component="img"
                      sx={{
                        width: 89,
                        height: 29,
                        marginLeft: "-20px",
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* Способы выкупа авто (правый блок) */}
            <Grid sm={5} item>
              <Box
                sx={{
                  height: "100%",
                  padding: "9px 22px",
                  backgroundColor: "#E6F1FF",
                  borderRadius: "4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack direction="column" rowGap="4px">
                  <TextLabel
                    color={(theme) =>
                      `${theme.palette.vtbAuto.BlueAccent}!important`
                    }
                  >
                    Способы выкупа авто
                  </TextLabel>

                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <FinanceSolid />
                    </IconWrapper>
                    <TextLabel>Автокредит</TextLabel>
                  </Stack>
                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <CreditSolid />
                    </IconWrapper>
                    <TextLabel>Покупка за собственные средства</TextLabel>
                  </Stack>
                  <Stack direction="row" columnGap="2px">
                    <IconWrapper>
                      <CloseSolid fill={purpleColor} />
                    </IconWrapper>
                    <TextLabel>Возможность отказаться от покупки</TextLabel>
                  </Stack>
                </Stack>
              </Box>
            </Grid>

            <Grid sm={11} item container columns={16}>
              {/* Обозначения на шкале */}
              <Grid item sm={2}>
                <Stack direction="row" justifyContent="center">
                  <PriceLabel margin="auto">20 000 ₽</PriceLabel>
                </Stack>
              </Grid>
              <Grid item sm={6}>
                <Stack direction="row" justifyContent="center">
                  <PriceLabel margin="auto">112 200 ₽/мес.</PriceLabel>
                </Stack>
              </Grid>
            </Grid>

            <Grid item sm={16}>
              <DescriptionLabel pale mt="4px">
                Пример расчетов. Предложение не является офертой.
                *Обеспечительный платеж
              </DescriptionLabel>
            </Grid>
          </Grid>
        </Box>
      )}

      {isMobile && (
        <Box
          borderTop={(theme) => `solid 1px ${theme.palette.vtbl.Silver}`}
          paddingTop="8px"
          marginTop="8px"
        >
          <Title marginBottom="8px">
            Пример расчета стоимости Jetour Dashing
          </Title>
          {/* Блок со стоимостью */}
          <Grid container spacing="4px">
            <Grid item xs={12} position="relative">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  padding: "8px 12px",
                  backgroundColor: pinkColor,
                  borderRadius: "4px",
                }}
              >
                <Text>Полная стоимость</Text>

                <Stack direction="column" alignItems="end">
                  <PriceSmall sx={{ textDecoration: "line-through" }}>
                    2 979 900 ₽
                  </PriceSmall>
                  <PriceBig>2 772 370 ₽</PriceBig>
                </Stack>
              </Stack>

              {/* Градиент абсолютно позиционированный */}
              <Box
                sx={{
                  height: 20,
                  width: "50%",
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  background: carGradient,
                }}
              />
              {/* Иконка машины абсолютно позиционированная */}
              <Box
                src={CarMoving}
                component="img"
                sx={{
                  width: 84,
                  height: 29,
                  position: "absolute",
                  left: "45%",
                  bottom: 0,
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: darkGreenColor,
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: darkBlueColor,
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: darkBlueColor,
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: darkBlueColor,
                  borderRadius: "4px",
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{
                  padding: "10px",
                  backgroundColor: lavenderColor,
                  borderRadius: "4px",
                }}
              >
                <Text>Авто переходит в собственность за</Text>
                <PriceBig>2 415 770 ₽</PriceBig>
                <TextSmall>Отложенный платеж</TextSmall>
              </Stack>
            </Grid>
          </Grid>

          <Stack direction="column" margin="8px 0" rowGap="5px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              columnGap="16px"
            >
              <Stack direction="row" alignItems="top" columnGap="3px">
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    marginTop: "2px",
                    borderRadius: "50%",
                    backgroundColor: darkGreenColor,
                    flex: "0 0 10px",
                  }}
                />
                <DescriptionLabel>Бронь*</DescriptionLabel>
              </Stack>
              <PriceBig>20 000 ₽</PriceBig>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              columnGap="16px"
            >
              <Stack direction="row" alignItems="top" columnGap="3px">
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    marginTop: "2px",
                    borderRadius: "50%",
                    backgroundColor: darkBlueColor,
                    flex: "0 0 10px",
                  }}
                />
                <DescriptionLabel>
                  Аренда — три месяца, оплата тремя равными платежами в конце
                  месяца
                </DescriptionLabel>
              </Stack>
              <PriceBig>112 200 ₽/мес.</PriceBig>
            </Stack>
          </Stack>

          {/* Способы выкупа авто (правый блок) */}

          <Stack
            direction="column"
            rowGap="4px"
            marginBottom="8px"
            padding="20px 15px"
            sx={{ backgroundColor: lavenderColor }}
            borderRadius="4px"
          >
            <TextLabel
              color={(theme) => `${theme.palette.vtbAuto.BlueAccent}!important`}
            >
              Способы выкупа авто
            </TextLabel>

            <Stack direction="row" alignItems="center" columnGap="2px">
              <IconWrapper>
                <FinanceSolid />
              </IconWrapper>
              <TextLabel>Автокредит</TextLabel>
            </Stack>
            <Stack direction="row" alignItems="center" columnGap="2px">
              <IconWrapper>
                <CreditSolid />
              </IconWrapper>
              <TextLabel>Покупка за собственные средства</TextLabel>
            </Stack>
            <Stack direction="row" alignItems="center" columnGap="2px">
              <IconWrapper>
                <CloseSolid fill={purpleColor} />
              </IconWrapper>
              <TextLabel>Возможность отказаться от покупки</TextLabel>
            </Stack>
          </Stack>

          <DescriptionLabel pale>
            Пример расчетов. Предложение не является офертой. <br />
            *Обеспечительный платеж
          </DescriptionLabel>
        </Box>
      )}
    </>
  );
};
