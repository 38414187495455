import useSWR from 'swr';

import { bff } from '~/services';

export const useRelatedCars = ({
  carId,
  carTag,
  carType,
}: {
  carId: string;
  carType: Parameters<typeof bff.getRelatedCars>[1];
  carTag?: Parameters<typeof bff.getRelatedCars>[2];
}) => {
  return useSWR(carId ? ['relatedCars', carId, carType] : null, ([, id, type]) =>
    bff.getRelatedCars(id, type, carTag),
  );
};
