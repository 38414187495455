import { FC } from 'react';

import CallIcon from '@mui/icons-material/Call';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SpeedDial, SpeedDialAction } from '@mui/material';

import { SUPPORT_PHONE, TELEGRAM_URL, WHATSAPP_URL } from '~/consts';

enum EActionType {
  Call,
  Telegram,
  Whatsapp,
}

const actions = [
  { icon: <WhatsAppIcon />, name: 'WhatsApp', type: EActionType.Whatsapp },
  { icon: <TelegramIcon />, name: 'Telegram', type: EActionType.Telegram },
  { icon: <CallIcon />, name: 'Позвонить', type: EActionType.Call },
];
export const FloatingActionButton: FC = () => {
  function handleActionClick(action: EActionType) {
    switch (action) {
      case EActionType.Call:
        window.open(`tel:${SUPPORT_PHONE}`, '_self');
        break;
      case EActionType.Telegram:
        window.open(TELEGRAM_URL, '_blank');
        break;
      case EActionType.Whatsapp:
        window.open(WHATSAPP_URL, '_blank');
        break;
    }
  }

  return (
    <SpeedDial
      ariaLabel='Call, telegram  and whatsapp'
      sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(2),
        right: (theme) => theme.spacing(2),
        '& > button': {
          background: (theme) => theme.palette.vtbAuto.PrimaryBlueGradient,
          '&:hover': {
            background: (theme) => theme.palette.vtbAuto.BlueAccent,
          },
        },
      }}
      icon={<MapsUgcOutlinedIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleActionClick(action.type)}
          sx={{
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.contrastText,
              color: (theme) => theme.palette.vtbAuto.BluePrimary,
            },
          }}
        />
      ))}
    </SpeedDial>
  );
};
