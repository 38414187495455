import { Skeleton } from '@mui/material';

export const TagsSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, idx) => (
          <Skeleton
            key={idx}
            sx={{ borderRadius: '8px' }}
            variant='rounded'
            width={126}
            height={32}
          />
        ))}
    </>
  );
};
