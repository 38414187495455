import type { ThemeOptions } from "@mui/material";

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    light: true;
    round: true;
  }
}

export const accordionOptions: ThemeOptions = {
  components: {
    MuiAccordion: {
      variants: [
        {
          props: { variant: "light" },
          style: {
            background: "transparent",
            border: "0",
            boxShadow: "none",
            fontWeight: 500,
            textTransform: "none",
            ['&:not(:first-of-type)::before']: {
              opacity: '1!important',
              display: 'block!important',
            },
          },
        },
        {
          props: { variant: "round" },
          style: ({theme}) => ({
            boxShadow: "none",
            fontWeight: 500,
            textTransform: "none",
            backgroundColor: theme.palette.common.white,
            borderRadius: "12px",
            padding: "4px 0",
            ['&::before']: {
              display: 'none',
            },
          }),
        },
      ],
    },
  },
};
