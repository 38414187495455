import {Button, ButtonGroup, styled, Typography} from "@mui/material";

export const SubscriptionButtonGroup = styled(ButtonGroup)`
  width: 100%;
  height: 40px;
  margin-bottom: 4px;

  ${({theme}) => theme.breakpoints.up('lg')} {
    height: 60px;
  }
`;

export const SubscriptionPeriodButton = styled(Button)`
  flex-grow: 0;
  flex-basis: 100%;
  border-radius: 4px;

  &&.MuiButton-text {
    background: #e9eff3;
    color: ${({theme}) => theme.palette.vtbAuto.PrimaryGray};

    & + & {
      border-left: 1px solid #cfd7dc;
    }
  }
`;

export const Run = styled(Typography)`
  color: ${({theme}) => theme.palette.vtbAuto.DarkGray};

`;


