import { FC, ReactNode, useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import CarImage from '~/assets/images/callback-form-car.webp';
import { useBreakpoints } from '~/hooks';
import { TCar } from '~/types';

import { CallbackFormWrapper } from './CallbackForm.styles';
import { Form, Success } from './components';

// 3 мин
const REQUEST_INTERVAL = 3 * 60 * 1000;

type Props = {
  car?: TCar;
  title: ReactNode;
  description?: ReactNode;
};
export const CallbackForm: FC<Props> = ({ car, title, description }) => {
  const { isDesktop } = useBreakpoints();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const showDesc = isDesktop || !isSubmitted;

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (isSubmitted) {
      timerId = setTimeout(() => setIsSubmitted(false), REQUEST_INTERVAL);
    }

    return () => clearTimeout(timerId);
  }, [isSubmitted]);

  return (
    <CallbackFormWrapper
      as='section'
      id='callback-form'
      direction={{ xs: 'column', lg: 'row' }}
      columnGap={8}
      alignItems={{ xs: 'center', lg: 'stretch' }}
      rowGap={2}
      justifyContent='space-between'
    >
      <Stack
        rowGap={4}
        alignItems={{ xs: 'center', lg: 'start' }}
        justifyContent='space-between'
      >
        {showDesc && (
          <Stack alignItems={{ xs: 'center', lg: 'start' }} rowGap={{ xs: 2, sm: 1, lg: 2 }}>
            <Typography
              textAlign={{ xs: 'center', lg: 'start' }}
              variant='h3'
              color={(theme) => theme.palette.common.white}
            >
              {title}
            </Typography>
            <Typography
              textAlign={{ xs: 'center', lg: 'start' }}
              color={(theme) => theme.palette.common.white}
            >
              {description}
            </Typography>
          </Stack>
        )}

        {isDesktop && <img width='313px' height='188px' src={CarImage} alt='Car image' />}
      </Stack>

      {isSubmitted ? <Success /> : <Form car={car} onSubmit={() => setIsSubmitted(true)} />}
    </CallbackFormWrapper>
  );
};
