import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeOptions } from '@mui/material';

export const autocompleteOptions: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
        noOptionsText: 'Не найдено',
      },
    },
  },
};
