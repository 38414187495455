import { ThemeOptions } from '@mui/material';

export const tooltipOptions: ThemeOptions = {
  components: {
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        leaveTouchDelay: 100000,
        placement: 'top',
        arrow: true,
        slotProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-4, -4],
                },
              },
            ],
          },
        },
      },
    },
  },
};
