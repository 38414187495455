import { FC, useEffect } from 'react';

import { Stack, Typography } from '@mui/material';

import SuccessImg from '~/assets/images/steering-wheel.webp';
import { useBreakpoints } from '~/hooks';
import { scrollToCallbackForm } from '~/utils';

export const Success: FC = () => {
  const { isMobile } = useBreakpoints();

  useEffect(() => scrollToCallbackForm(), []);

  return (
    <Stack
      direction='column'
      alignItems='center'
      rowGap={{ xs: '8px', lg: '20px' }}
      textAlign={{ xs: 'center', lg: 'start' }}
    >
      <Typography variant={isMobile ? 'h4' : 'h3'} color={(theme) => theme.palette.common.white}>Благодарим вас за заявку!</Typography>
      <Typography color={(theme) => theme.palette.common.white}>Свяжемся с вами в ближайшее время</Typography>

      <img
        width={isMobile ? '196px' : '295px'}
        height={isMobile ? '169px' : '254px'}
        src={SuccessImg}
      />
    </Stack>
  );
};
