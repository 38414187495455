import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';

import { ReactComponent as PdfIcon } from '~/assets/icons/pdf-outline.svg';

export const StyledButton = styled(Button)<ButtonProps & { target: string }>`
  justify-content: start;
  flex: 1 0 0;
  border-color: ${({ theme }) => theme.palette.vtbl.Silver} !important;
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent} !important;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent} !important;
    background-color: transparent !important;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;
    max-width: ${({ theme }) => theme.spacing(37)};
  }

  & .MuiButton-icon svg {
    fill: ${({ theme }) => theme.palette.vtbAuto.BlueAccent} !important;
  }
`;

StyledButton.defaultProps = {
  variant: 'outlined',
  startIcon: <PdfIcon />,
};

export const IconWrapper = styled('div')`
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};

  & > svg {
    width: ${({ theme }) => theme.spacing(2)};
    height: ${({ theme }) => theme.spacing(2)};
  }
`;
