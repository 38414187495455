import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import { DEFAULT_FILTERS } from '~/consts';
import {
  useBreakpoints,
  useIsSubscription,
  useQueryArrayParam,
  useQueryNumberParam,
  useQueryStringParam,
  useSetQueryParams,
} from '~/hooks';
import { useAllBrandsAndModels, useAllTags, useSearchCars } from '~/queries';
import { EParamKeys, TCarBrand, TCarModel, TSortOrder } from '~/types';

import { TagsSkeleton } from './Filters.skeleton';
import { StyledTag } from './Filters.styles';

export const Filters: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { isMobile } = useBreakpoints();
  const isSubscription = useIsSubscription();
  const filtersWrapperRef = useRef<HTMLDivElement | null>(null);

  const { data: tags, isValidating: isAllTagsValidating } = useAllTags();
  const { data: availableBrands = [], isValidating: isAllBrandsAndModelsValidating } =
    useAllBrandsAndModels();
  const { data: searchCars } = useSearchCars();

  const brandIds = useQueryArrayParam(EParamKeys.Brands, []);
  const modelIds = useQueryArrayParam(EParamKeys.Models, []);
  const tag = useQueryStringParam(EParamKeys.Tag, '');
  const page = useQueryNumberParam(EParamKeys.Page, 1);
  const sortDirection = useQueryStringParam(EParamKeys.SortDirection, '');
  const setQueryParams = useSetQueryParams();

  const getAvailableModels = useCallback((availableBrands: TCarBrand[], brandIds?: string[]) => {
    return (
      availableBrands?.reduce<TCarModel[]>((acc, brand) => {
        // TODO name -> id
        if (brandIds?.includes(brand.name)) {
          return [...acc, ...brand.models];
        }
        return acc;
      }, []) ?? []
    );
  }, []);

  // Доступные модели
  const availableModels = useMemo<TCarModel[]>(
    () => getAvailableModels(availableBrands, brandIds),
    [availableBrands, brandIds],
  );

  useEffect(() => {
    // В случае когда марки уже нет в списке выбора - удаляем его из query params
    const filteredBrandIds = brandIds.filter((brandId) =>
      availableBrands.some((availableBrand) => availableBrand.name === brandId),
    );

    // В случае когда модели уже нет в списке выбора - удаляем его из query params
    const filteredModelIds = modelIds.filter((modelId) =>
      availableModels.some((availableModel) => availableModel.name === modelId),
    );

    // Делаем проверку на длину массива, чтобы избежать цикла
    if (
      filteredBrandIds.length !== brandIds.length ||
      filteredModelIds.length !== modelIds.length
    ) {
      setQueryParams({
        [EParamKeys.Brands]: filteredBrandIds,
        [EParamKeys.Models]: filteredModelIds,
      });
    }
  }, [availableBrands, availableModels, brandIds, modelIds]);

  const brands = useMemo<TCarBrand[]>(
    // TODO name -> id
    () => availableBrands?.filter(({ name }) => brandIds.includes(name)) ?? [],
    [availableBrands, brandIds],
  );

  const models = useMemo<TCarModel[]>(
    // TODO name -> id
    () => availableModels?.filter(({ name }) => modelIds.includes(name)) ?? [],
    [availableModels, modelIds],
  );

  const handleBrandsSelect = (values: TCarBrand[]) => {
    // Map objects to ids
    // TODO name -> id
    const brandIds = values.map(({ name }) => name);
    // Get available models by brand ids
    const availableModels = getAvailableModels(availableBrands, brandIds);
    // Map objects to ids
    // TODO name -> id
    const availableModelIds = availableModels.map(({ name }) => name);
    // Filter selected model ids
    const filteredModelIds = modelIds.filter((_) => availableModelIds.includes(_));
    setQueryParams({
      [EParamKeys.Brands]: brandIds,
      [EParamKeys.Models]: filteredModelIds,
      [EParamKeys.Page]: '1',
    });
  };

  const handleModelsSelect = (values: TCarModel[]) => {
    setQueryParams({
      // TODO name -> id
      [EParamKeys.Models]: values.map(({ name }) => name),
      [EParamKeys.Page]: '1',
    });
  };

  const handleSortSelect = (value: TSortOrder) => {
    setQueryParams({
      [EParamKeys.SortDirection]: value,
      [EParamKeys.Page]: '1',
    });
  };

  const handleFiltersReset = () => {
    setQueryParams({ ...DEFAULT_FILTERS });
  };

  const handlePageChange = (page: number) => {
    // Scroll to filters
    filtersWrapperRef.current?.scrollIntoView();
    setQueryParams({
      [EParamKeys.Page]: page.toString(),
    });
  };

  const handleTagClick = (label: string) => {
    setQueryParams({
      [EParamKeys.Tag]: label === tag ? '' : label,
      [EParamKeys.Page]: '1',
    });
  };

  const inputSize = isMobile ? 'medium' : 'small';
  const showPagination = searchCars && searchCars.last_page > 1;

  return (
    <Stack rowGap={{ xs: 2, sm: 3, lg: 4 }} ref={filtersWrapperRef}>
      {!isSubscription && (
        <Stack direction='row' columnGap={{ xs: 1, sm: 2 }}>
          {isAllTagsValidating && <TagsSkeleton />}
          {Array.isArray(tags) &&
            tags.map(({ label, color }) => (
              <StyledTag
                variant='contained'
                color={label.toLowerCase() === tag.toLowerCase() ? 'primary' : 'secondary'}
                colorToken={color}
                size={isMobile ? 'large' : 'medium'}
                key={label}
                sx={{
                  flex: isMobile ? '1 0 0!important' : undefined,
                }}
                onClick={() => handleTagClick(label)}
              >
                {label}
              </StyledTag>
            ))}
        </Stack>
      )}

      <Grid container spacing={{ xs: 2, sm: 3, lg: 4 }}>
        <Grid item xs={12} sm={4} lg={3}>
          <Autocomplete
            multiple
            limitTags={1}
            value={brands}
            options={availableBrands}
            size={inputSize}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label='Марка' />}
            disabled={isAllBrandsAndModelsValidating}
            onChange={(_, values) => handleBrandsSelect(values)}
            renderOption={(props, option) => {
              // @ts-ignore
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  <img
                    loading='lazy'
                    width={isMobile ? '36px' : '24px'}
                    src={`/assets/icons/car-logos/${option.id}.svg`}
                    alt=''
                  />
                  {option.name}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Autocomplete
            multiple
            limitTags={1}
            value={models}
            options={availableModels}
            size={inputSize}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label='Модель' />}
            disabled={isAllBrandsAndModelsValidating}
            onChange={(_, values) => handleModelsSelect(values)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <FormControl fullWidth size={inputSize}>
            <InputLabel id='sort-select'>Сортировка</InputLabel>
            <Select
              labelId='sort-select'
              id='sort-select'
              label='Сортировка'
              value={sortDirection}
              onChange={({ target: { value } }) => handleSortSelect(value as TSortOrder)}
            >
              <MenuItem value='asc'>Сначала дешевые</MenuItem>
              <MenuItem value='desc'>Сначала дорогие</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button
            fullWidth
            variant='outlined'
            size={isMobile ? 'large' : 'medium'}
            sx={{
              padding: isMobile ? undefined : '6px 16px!important',
            }}
            onClick={handleFiltersReset}
          >
            Сбросить фильтр
          </Button>
        </Grid>
      </Grid>

      {children}

      {showPagination && (
        <Box display='flex' justifyContent='center'>
          <Pagination
            variant='text'
            shape='circular'
            size={isMobile ? 'large' : 'medium'}
            count={searchCars?.last_page}
            page={page}
            onChange={(_, page) => handlePageChange(page)}
            boundaryCount={isMobile ? 0 : 1}
            disabled={!searchCars?.last_page}
          />
        </Box>
      )}
    </Stack>
  );
};
