import { FC, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';

import Cone from 'assets/images/cone.webp';
import Docs from 'assets/images/docs.webp';
import Family from 'assets/images/family.webp';
import LittleCar from 'assets/images/little-car.webp';
import Travel from 'assets/images/travel.webp';
import Wheel from 'assets/images/wheel.webp';

import { useBreakpoints } from '~/hooks';

import { Description, Image, Title } from './SubscriptionTiles.styles';

export const SubscriptionTiles: FC = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();

  const items = useMemo(
    () => [
      {
        title: 'Легкое оформление',
        description:
          'Мы оплатим госпошлину за постановку на учет, транспортный и все остальные налоги',
        imageUrl: Docs,
      },
      {
        title: 'Полное обслуживание',
        description:
          'Возьмем на себя страхование, ТО, ремонты, смену шин и другие заботы. На вас только топливо и мойки',
        imageUrl: Wheel,
      },
      {
        title: 'Консьерж-сервис',
        description: 'Заберем авто в сервис и доставим в удобное для вас место',
        imageUrl: LittleCar,
      },
      {
        title: 'Помощь на дороге',
        description: 'Эвакуатор, помощь при ДТП, подвоз топлива, зарядка аккумулятора и другое',
        imageUrl: Cone,
      },
      {
        title: 'Семейный доступ',
        description:
          'Вы можете допустить к управлению своих близких. Данная опция бесплатна, для этого необходимо обратиться к менеджеру',
        imageUrl: Family,
      },
      {
        title: (
          <>
            Без границ&nbsp;
            <Tooltip title='На территориях, по которым есть страховое покрытие (кроме ЛНР, ДНР)'>
              <IconButton size='small'>
                <InfoOutlinedIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </>
        ),
        description:
          'Мы не ограничиваем ваши поездки чертой города, вы можете путешествовать по всей России',
        imageUrl: Travel,
      },
    ],
    [],
  );

  return (
    <section>
      {(isDesktop || isTablet) && (
        <Grid container spacing={{ sm: 3, lg: 4 }}>
          {items.map((item, index) => (
            <Grid item xs={6} key={index}>
              <Stack direction='row' alignItems={isDesktop ? 'center' : 'top'} columnGap={1}>
                <Image src={item.imageUrl} />
                <Stack direction='column' rowGap={1}>
                  <Title>{item.title}</Title>
                  <Description>{item.description}</Description>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}

      {isMobile && (
        <Stack rowGap={1}>
          {items.map((item, index) => (
            <Accordion variant='round' key={index} elevation={0} disableGutters square>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction='row' alignItems='center' columnGap={1}>
                  <Image src={item.imageUrl} />
                  <Title>{item.title}</Title>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Description>{item.description}</Description>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      )}
    </section>
  );
};
