import React, { FC, Fragment, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';
import { separateThousands } from '~/utils';

import { propertiesConfig, propertiesOrder, usedCarPropertiesOrder } from './CarProperties.config';
import { CarPropertiesSkeleton } from './CarProperties.skeleton';
import { TProperties } from './CarProperties.types';
import { PropertyItem } from './components';

type Props = {
  car?: TCar;
};
export const CarProperties: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  const isUsed = !isSubscription && (car?.owners_number || 0) > 0;

  const properties: TProperties | undefined = useMemo(() => {
    if (!car) {
      return;
    }

    const data: TProperties = {
      complectation_name: car.complectation_name,
      engine_eco: `Евро ${car.engine_eco}`,
      engine: `${car.engine_type} / ${car.engine} л.`,
      registry_year: `${car.registry_year}${car.owners_number ? '' : ' / Новый'}`,
      power_hp: `${car.power_hp} л.с.`,
      body_type: car.body_type,
      drive: car.drive,
      gearbox: car.gearbox,
    };

    if (isUsed) {
      data.mileage = car.mileage ? `${separateThousands(car.mileage)} км` : '';
      data.owners_number = car.owners_number ? `${car.owners_number} по ПТС` : '';
    }

    return data;
  }, [car, isUsed]);

  if (!car || !properties) {
    return <CarPropertiesSkeleton />;
  }

  const toCapitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <Stack rowGap={{ xs: 3, lg: 4 }}>
      <Grid container columns={isUsed ? 10 : 12} spacing={{ xs: 2, sm: 3, lg: 4 }}>
        {(isUsed ? usedCarPropertiesOrder : propertiesOrder).map((key, idx) => {
          const text = properties[key];
          const { title, Icon } = propertiesConfig[key];

          if (!text) {
            return null;
          }
          return (
            <Grid item xs={isUsed ? 5 : 6} md={isUsed ? 2 : 3} key={idx}>
              <PropertyItem title={title} text={text} Icon={Icon} />
            </Grid>
          );
        })}
      </Grid>

      <Box>
        {Boolean(car.accordion?.length) &&
          car.accordion!.map(({ title, content }) => {
            return (
              <Accordion variant='light' elevation={0} square disableGutters key={title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography color={(theme) => theme.palette.vtbAuto.DarkGray}>
                    {toCapitalize(title)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack rowGap={{ xs: 0.5, lg: 1 }}>
                    {[content].flat().map((item, index) => {
                      if (item.type === 'paragraph') {
                        return (
                          <Typography
                            key={index}
                            color={(theme) => theme.palette.vtbAuto.PrimaryGray}
                          >
                            <Stack direction='row' columnGap={0.5}>
                              <span>—</span>
                              <span>{item.text.slice(1, item.text.length)}</span>
                            </Stack>
                          </Typography>
                        );
                      } else {
                        return (
                          <Fragment key={index}>
                            {item.items.map((item, index) => (
                              <Typography color={(theme) => theme.palette.vtbAuto.PrimaryGray}>
                                <Stack direction='row' columnGap={0.5}>
                                  <span>—</span>
                                  <span>{item}</span>
                                </Stack>
                              </Typography>
                            ))}
                          </Fragment>
                        );
                      }
                    })}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>
    </Stack>
  );
};
