import { FC } from 'react';

import { Grid, Skeleton } from '@mui/material';

import { PER_PAGE_NUMBER } from 'consts';

type Props = {
  isRelated?: boolean;
};
export const CarCardsSkeleton: FC<Props> = ({ isRelated }) => {
  return (
    <>
      {Array(PER_PAGE_NUMBER + 1)
        .fill(null)
        .map((_, idx) => (
          <Grid item xs={isRelated ? 6 : 12} sm={6} lg={3}>
            <Skeleton key={idx} variant='rounded' sx={{ height: { xs: '360px', lg: '476px' } }} />
          </Grid>
        ))}
    </>
  );
};
