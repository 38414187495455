import type { FC, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

import { useBreakpoints } from '~/hooks';

type Props = {
  header?: ReactNode;
  items: ReactNode[];
};
export const CarDescription: FC<Props> = ({ header, items }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <Stack rowGap={2}>
      {header && (
        <Typography
          variant="h6"
          color={(theme) => theme.palette.vtbAuto.BlueAccent}
        >
          {header}
        </Typography>
      )}

      <Stack rowGap={1}>
        {items.map((value, index) => (
          <Typography key={index} color={(theme) => theme.palette.vtbAuto.DarkGray}>
            <Stack direction='row' columnGap={0.5}>
              <span>—</span>
              <span>{value}</span>
            </Stack>
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};
