import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { COOKIES_UTM_TAGS_KEY } from '~/consts';

/**
 * Заполняет cookie параметрами, необходимыми для чтения UTM меток
 * */
export const UtmTagsProcessor = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIES_UTM_TAGS_KEY]);

  useEffect(() => {
    if (!document.referrer || !window.location.href.includes('utm_')) {
      return;
    }

    let sourceInfo = Array.isArray(cookies.source_info) ? cookies.source_info : [];
    sourceInfo.unshift({
      time: new Date().toISOString(),
      referer: document.referrer || 'https://null.null',
      url: window.location.href,
    });
    sourceInfo = sourceInfo.slice(0, 4);

    setCookie(COOKIES_UTM_TAGS_KEY, sourceInfo, {
      path: '/',
      maxAge: 60 * 60 * 24 * 14, // 2 weeks in seconds
      secure: true,
      sameSite: 'none',
    });
  }, []);

  return null;
};
