import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import VtbAutoLogo from '~/assets/images/vtb-auto-logo.webp';
import { routeConfig } from '~/routeConfig';

export const Logo: FC = () => {
  return (
    <Link to={routeConfig.PurchaseCatalogue.path}>
      <Box component='img' width='136px' src={VtbAutoLogo} alt='VTB Auto Logo' />
    </Link>
  );
};
