import { Stack } from "@mui/material";
import { styled } from '@mui/system';

export const CallbackFormWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "component"
})`
  background: ${({theme}) => theme.palette.vtbAuto.PrimaryBlueGradient};
  border-radius: 10px;
  padding: 24px 16px;

  ${({theme}) => theme.breakpoints.up("sm")} {
    padding: 50px;
  }

  ${({theme}) => theme.breakpoints.up("lg")} {
    padding: 88px 110px;
  }
`;
