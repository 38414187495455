import { FC } from 'react';

import { Grid } from '@mui/material';

import { TCar } from '~/types';

import { CarInfo, CarSlider } from './components';

type Props = {
  car?: TCar;
};
export const CarDetails: FC<Props> = ({ car }) => {
  return (
    <Grid container spacing={{ sx: 1, lg: 4 }}>
      <Grid item xs={12} lg={8}>
        <CarSlider car={car} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CarInfo car={car} />
      </Grid>
    </Grid>
  );
};
