const PrimaryBlueGradientColor1 = "#0037FF";
const PrimaryBlueGradientColor2 = "#0085FF";

export const VtbAutoColors = {
  BlueAccent: "#0A2896",
  BluePrimary: "#3261EC",
  BlueDark: "#20364C",
  BluePale: "#B7C8D8",
  PrimaryBlueGradientColor1,
  PrimaryBlueGradientColor2,
  PrimaryBlueGradient: `linear-gradient(90deg, ${PrimaryBlueGradientColor1}, ${PrimaryBlueGradientColor2})`,
  DarkBlueGradient: `linear-gradient(90deg, #1134B5, #2353E0)`,
  GradientSale: "linear-gradient(to right, #820550, #C90677)",
  GradientPrimaryBlueViolet: `linear-gradient(to right, ${PrimaryBlueGradientColor1}, #007CEE, #BE43FF)`,
  GradientGreen: `linear-gradient(138.09deg, #006E97, #81D0B4)`,
  GradientPurple: `linear-gradient(90.00deg, #820550, #C90677)`,
  DarkGray: "#2F3441",
  CardSale: "#900258",
  PrimaryGray: "#6B7683",
  AccentCobalt: "#03277F",
  HawaiianSky: "#85A7BE",
  AliceBlue: "#EEF8FF",
};
