import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';

import SadCar from '~/assets/images/sad-car.webp';
import { useBreakpoints } from '~/hooks';

type Props = {
  title: string;
  description?: string;
  buttonText?: string;
  buttonHref?: string;
};
export const NotFound: FC<Props> = ({ title, description, buttonText, buttonHref }) => {
  const { isMobile } = useBreakpoints();
  const imageSize = isMobile ? '150px' : '300px';

  return (
    <Stack alignItems='center' rowGap={{ xs: 3, lg: 4 }}>
      <Stack alignItems='center' rowGap={2}>
        <img width={imageSize} height={imageSize} src={SadCar} alt='Нет изображения' />
        <Typography variant='h5' textAlign='center'>
          {title}
        </Typography>
        {description && (
          <Typography color={(theme) => theme.palette.vtbAuto.PrimaryGray} textAlign='center'>
            {description}
          </Typography>
        )}
      </Stack>

      {buttonText && buttonHref && (
        <Button
          component={Link}
          to={buttonHref}
          variant='outlined'
          size='large'
          fullWidth={isMobile}
          sx={{
            minWidth: '50%',
          }}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};
