import { FC } from 'react';

import { ChipProps } from '@mui/material';

import { TCarTagColor } from '~/types';

import { Tag } from './CarTag.styles';

type Props = ChipProps & {
  colorToken: TCarTagColor;
  isSpecialOffer?: boolean
};

export const CarTag: FC<Props> = ({ colorToken, isSpecialOffer, ...props }) => {
  return <Tag variant='tag' colorToken={colorToken} isSpecialOffer={isSpecialOffer} {...props} />;
};
