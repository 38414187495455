import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const PriceWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.between('xs', 'sm')} {
    flex-direction: column;
    align-items: start;
  }
`;

export const NewPrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
  font-weight: 500;
`;
NewPrice.defaultProps = {
  variant: 'h5',
};

export const OldPrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
  text-decoration: line-through;
`;
