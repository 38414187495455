import useSWR from 'swr';

import { useIsSubscription, useQueryStringParam } from '~/hooks';
import { bff } from '~/services';
import { EParamKeys } from '~/types';

export const useAllBrandsAndModels = () => {
  const tag = useQueryStringParam(EParamKeys.Tag, '');
  const isSubscription = useIsSubscription();
  return useSWR(['allBrandsAndModels', tag, isSubscription], () =>
    bff.getAllBrandsAndModels({ newTags: [tag], isSubscription }),
  );
};
