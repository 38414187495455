import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import { CarTag, Slider } from '~/components';
import { useBreakpoints, useIsSubscription } from '~/hooks';
import { routeConfig } from '~/routeConfig';
import { TCar, TCarTag } from '~/types';
import { getCarOptions, separateThousands } from '~/utils';

import {
  ButtonStyled,
  Card,
  Complectation,
  ImageWrapper,
  StyledTagsStack,
  Title,
} from './CarCard.styles';
import { Price, SlideImageWrapper } from './components';

type Props = {
  car: TCar;
  isRelated?: boolean;
  tagToShow?: string;
  showSelfTag?: boolean;
};

export const CarCard: FC<Props> = ({
  car,
  isRelated = false,
  tagToShow = '',
  showSelfTag = false,
}) => {
  const { isDesktop } = useBreakpoints();
  const isSubscription = useIsSubscription();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Задаем статичную высоту изображения в слайдере
    // Соотношение изображения может быть 4 на 3
    const resizeObserver = new ResizeObserver(() => {
      const cardWidth = ref.current?.getBoundingClientRect().width || 0;
      setImageHeight((cardWidth / 4) * 3);
    });

    resizeObserver.observe(ref.current as HTMLAnchorElement);

    // Disconnect observer
    return () => resizeObserver.disconnect();
  }, [ref.current]);

  const visibleCarTags = useMemo<ReactNode>(() => {
    let tag: TCarTag | undefined;

    if (!isSubscription && Array.isArray(car.newTags) && car.newTags.length > 0) {
      if (tagToShow && car.newTags.some((tag) => tag.label == tagToShow)) {
        tag = car.newTags.find((tag) => tag.label == tagToShow);
      }

      if (showSelfTag) {
        tag = car.newTags[0];
      }
    }

    return (
      <>
        {tag && <CarTag label={tag.label} size='small' colorToken={tag.color} />}
        {car.count === 0 && !isRelated && (
          <CarTag label='Нет в наличии' size='small' colorToken='black' />
        )}
        {car.special_offer && !isRelated && <CarTag label='🔥' isSpecialOffer size='small' colorToken='white' />}
      </>
    );
  }, [tagToShow, car, showSelfTag, isRelated, isSubscription]);

  const isUsed = car.owners_number > 0;
  const path = generatePath(
    `/${isSubscription ? routeConfig.SubscriptionCard.path : routeConfig.PurchaseCard.path}`,
    { id: car.id } as any,
  );

  return (
    <Card to={path} ref={ref}>
      <Box>
        {Array.isArray(car.images) && (
          <Slider enableMouseMoveSlide lazyLoad='ondemand' fade infinite waitForAnimate={false}>
            {car.images.slice(0, 5).map((url, idx) => (
              <ImageWrapper key={url} height={imageHeight}>
                <SlideImageWrapper src={url} />
              </ImageWrapper>
            ))}
          </Slider>
        )}
      </Box>

      <Stack spacing={2} padding={2} paddingTop={2} justifyContent='space-between' height='100%'>
        <Stack spacing={0.5}>
          <Price car={car} />
          <Title>{car.name}</Title>
          <Complectation>{car.complectation_name}</Complectation>
        </Stack>

        <Stack spacing={2}>
          <Typography color={(theme) => theme.palette.vtbAuto.PrimaryGray}>
            {getCarOptions(
              isSubscription
                ? [
                    car.registry_year.toString(),
                    car.engine_type,
                    car.power_hp + ' л.с.',
                    car.engine + ' л.',
                    car.gearbox,
                    car.drive,
                    car.color,
                  ]
                : isUsed
                  ? [
                      car.registry_year.toString(),
                      car.mileage ? separateThousands(car.mileage) + ' км' : '',
                      car.engine_type,
                      car.power_hp + ' л.с.',
                      car.engine + ' л.',
                      car.gearbox,
                      car.drive,
                      car.color,
                    ]
                  : [
                      car.registry_year.toString(),
                      car.engine_type,
                      car.power_hp + ' л.с.',
                      car.engine + ' л.',
                      car.gearbox,
                      car.drive,
                      car.color,
                    ],
              3,
            )}
          </Typography>

          {isDesktop && (
            <ButtonStyled size='medium' variant='contained'>
              Подробнее
            </ButtonStyled>
          )}
        </Stack>
      </Stack>
      <StyledTagsStack direction='row'>{visibleCarTags}</StyledTagsStack>
    </Card>
  );
};
