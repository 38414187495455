import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { IconContainer } from './PropertyItem.styles';

type Props = {
  title: string;
  text?: string;
  Icon: any;
};
export const PropertyItem: FC<Props> = ({ title, text, Icon }) => {
  return (
    <Stack direction='row' columnGap={1}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Stack>
        <Typography variant='body2' color={(theme) => theme.palette.vtbAuto.PrimaryGray}>
          {title}
        </Typography>
        <Typography fontWeight={500} color={(theme) => theme.palette.vtbAuto.DarkGray}>
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
};
