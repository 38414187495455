import { Chip } from '@mui/material';
import { styled } from '@mui/system';

import { css } from '@emotion/react';

import { TCarTagColor } from '~/types';

export const Tag = styled(Chip, {
  shouldForwardProp: (prop) => 'colorToken' !== prop && 'isSpecialOffer' != prop,
})<{ colorToken: TCarTagColor; isSpecialOffer?: boolean }>`
  &.MuiChip-root {
    &.MuiChip-sizeSmall {
      font-size: 0.625rem; // 10px;
      height: 16px;
    }

    &.MuiChip-sizeMedium {
      font-size: 0.75rem; // 12px;
      height: 24px;
    }

    ${({ isSpecialOffer }) =>
      isSpecialOffer &&
      css`
        &.MuiChip-sizeSmall {
          font-size: 0.875rem; // 14px;

          .MuiChip-label {
            padding: 0 4px;
          }
        }

        &.MuiChip-sizeMedium {
          font-size: 1rem; // 16px;

          .MuiChip-label {
            padding: 0 6px;
          }
        }
      `}

    background: ${({ theme, colorToken }) => {
      switch (colorToken) {
        case 'blue':
          return theme.palette.vtbAuto.GradientPrimaryBlueViolet;
        case 'green':
          return theme.palette.vtbAuto.GradientGreen;
        case 'purple':
          return theme.palette.vtbAuto.GradientPurple;
        case 'white':
          return theme.palette.common.white;
        case 'black':
        default:
          return theme.palette.vtbAuto.DarkGray;
      }
    }};
  }
`;
