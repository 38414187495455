import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { Box, Button, Link, Stack, useTheme } from '@mui/material';

import { useBreakpoints } from '~/hooks';
import { routeConfig } from '~/routeConfig';
import { scrollToCallbackForm } from '~/utils';

import { Logo } from '../Logo';
import { StyledContainer, StyledHeader } from './Header.styles';

const menuItems = [
  { name: 'Покупка', to: routeConfig.PurchaseCatalogue.path },
  { name: 'Подписка', to: routeConfig.SubscriptionCatalogue.path },
];

export const Header: FC = () => {
  const { isDesktop } = useBreakpoints();
  const theme = useTheme();

  return (
    <StyledHeader>
      <StyledContainer>
        <Stack direction='row' columnGap={9} alignItems='center' component='nav'>
          <Logo />

          {isDesktop && (
            <Stack
              direction='row'
              alignItems='center'
              columnGap={4}
              component='ul'
              sx={{
                padding: 0,
              }}
            >
              {menuItems.map(({ name, to }) => (
                <Box
                  key={to}
                  component='li'
                  sx={{
                    listStyle: 'none',
                  }}
                >
                  <Link
                    component={NavLink}
                    // @ts-ignore
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            color: theme.palette.vtbAuto.BluePrimary,
                            cursor: 'default',
                            fontWeight: 500,
                          }
                        : { color: theme.palette.vtbAuto.BlueAccent };
                    }}
                    to={to}
                    underline='none'
                    variant='body1'
                  >
                    {name}
                  </Link>
                </Box>
              ))}
            </Stack>
          )}
        </Stack>

        {isDesktop && (
          <Button
            variant='outlined'
            onClick={scrollToCallbackForm}
            sx={{
              '&&': {
                paddingLeft: '20px',
                paddingRight: '20px',
                height: '42px',
              },
            }}
          >
            Оставить заявку
          </Button>
        )}
      </StyledContainer>
    </StyledHeader>
  );
};
