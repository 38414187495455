import { Link } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { css } from '@emotion/react';

const lineClamp = (lines: number) => css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-decoration: none;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const ImageWrapper = styled(Box)`
  border-top-left-radius: ${({ theme }) => theme.spacing(1)};
  border-top-right-radius: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  ${lineClamp(2)}
`;
Title.defaultProps = {
  variant: 'h5',
};

export const Complectation = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
  ${lineClamp(1)}
`;
Complectation.defaultProps = {
  variant: 'h6',
};

export const ButtonStyled = styled(Button)`
  margin-top: auto;

  && {
    background: ${({ theme }) => theme.palette.vtbl.WhiteSmoke};
    color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};

    &:hover {
      background: ${({ theme }) => theme.palette.vtbAuto.PrimaryBlueGradient};
      color: ${({ theme }) => theme.palette.common.white};
    }

    &:focus {
      background: ${({ theme }) => theme.palette.vtbAuto.DarkBlueGradient};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const StyledTagsStack = styled(Stack)(({ theme }) => ({
  columnGap: theme.spacing(0.5),
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));
