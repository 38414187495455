import { FC, useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Stack, Typography } from '@mui/material';

import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_PHONE_LABEL } from '~/consts';
import { useIsSubscription } from '~/hooks';

export const Faq: FC = () => {
  const isSubscription = useIsSubscription();

  const purchaseItems = useMemo(
    () => [
      {
        title: 'Что входит в фиксированный платеж в первые три месяца?',
        description:
          'Полное страхование, КАСКО и ОСАГО. Пока автомобиль находится в аренде, мы регистрируем его и платим за него налоги.',
      },
      {
        title: 'Есть ли рекламная оклейка автомобилей?',
        description:
          'На автомобилях ВТБ Авто отсутствует рекламная оклейка. Ваш автомобиль не будет отличаться от остальных на дороге.',
      },
      {
        title: 'Есть ли ограничения по пробегу в первые три месяца?',
        description:
          'В течение трехмесячного периода аренды пробег ограничен 7,5 тыс. км. Если вы превысите данный лимит, то в конце периода аренды, при условии, что вы не выкупаете автомобиль, мы просто рассчитаем вам доплату. Доплата рассчитывается по формуле, указанной в правилах аренды, в зависимости от даты возврата.',
      },
      {
        title: 'А если я передумаю выкупать автомобиль спустя 3 месяца?',
        description: 'Денежные средства за период аренды не возвращаются.',
      },
      {
        title: 'Может ли другой человек водить мой автомобиль во время аренды?',
        description: 'По согласованию с сервисом.',
      },
    ],
    [],
  );

  const subscriptionItems = useMemo(
    () => [
      {
        title: 'Кто может оформить подписку?',
        description: 'Подписку может оформить гражданин РФ старше 21 года.',
      },
      {
        title: 'Куда можно ездить на арендованном автомобиле?',
        description:
          'Мы не ограничиваем вас чертой города, вы можете путешествовать по всей России.',
      },
      {
        title: 'Как оплачивать подписку?',
        description: 'Каждый месяц мы будем направлять вам счет на оплату ежемесячного платежа.',
      },
      {
        title: 'Машины новые или с пробегом?',
        description: 'Клиенты сервиса получают в пользование абсолютно новые автомобили.',
      },
      {
        title: 'Как записаться на ТО или шиномонтаж?',
        description: (
          <Stack rowGap={1}>
            <Box>
              Для записи на ТО или шиномонтаж позвоните по указанному номеру, и мы запишем вас в
              удобный сервис в комфортное время, а также доставим автомобиль на обслуживание.
            </Box>
            <Link href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE_LABEL}</Link>
            <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
          </Stack>
        ),
      },
      {
        title: 'Можно ли выехать на арендованном автомобиле за пределы страны?',
        description: 'К сожалению, нет, но вы можете путешествовать на нем по всей России.',
      },
      {
        title: 'Что будет, если я захочу вернуть автомобиль до завершения срока подписки?',
        description: 'В случае досрочного расторжения договора аренды необходимо внести неустойку.',
      },
      {
        title: 'Какой минимальный срок подписки?',
        description: 'Вы можете арендовать автомобиль на срок от 6 месяцев.',
      },
      {
        title: 'Что не входит в ежемесячный платеж?',
        description:
          'В стоимость подписки не входят топливо, мойка, оплата парковок и дорог, а также штрафы за нарушения ПДД. Вам нужно будет оплачивать их самостоятельно.',
      },
      {
        title: 'Как происходит оплата штрафов ГИБДД?',
        description: 'Мы отправим вам QR-код на оплату штрафа на вашу электронную почту.',
      },
      {
        title: 'Какие документы нужны для оформления подписки?',
        description: 'Вам понадобятся только 2 документа: паспорт и водительское удостоверение.',
      },
      {
        title: 'Есть ли ограничения по пробегу?',
        description:
          'Стандартный пробег — 20 тыс. км в год. По запросу мы можем рассчитать стоимость подписки на увеличение пробега до 30 тыс. км в год. Если вы превысите стандартный пробег, то доплата рассчитывается по формуле, указанной в правилах аренды в зависимости от даты возврата.',
      },
      {
        title: 'Как происходит выдача автомобиля?',
        description:
          'Мы бесплатно доставим автомобиль к вашему дому и в конце срока аренды сами его заберем.',
      },
      {
        title: 'Кто оплачивает ремонт автомобиля, если я попал в ДТП?',
        description:
          'Все наши автомобили застрахованы полисами КАСКО (без франшизы, включен аварийный комиссар), ДСАГО (покрытие до 1,5 млн рублей) и ОСАГО. В случае ДТП вам необходимо зарегистрировать страховое событие. В оформлении поможет аварийный комиссар, услуги которого входят в ваш полис КАСКО.',
      },
    ],
    [],
  );

  return (
    <section>
      <Typography variant='h5' mb={1} color={(theme) => theme.palette.vtbAuto.BlueAccent}>
        {isSubscription
          ? 'Что нужно знать о подписке'
          : 'Что нужно знать о покупке c оплатой частями'}
      </Typography>
      {(isSubscription ? subscriptionItems : purchaseItems).map((item, index) => (
        <Accordion variant='light' elevation={0} disableGutters square key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color={(theme) => theme.palette.vtbAuto.DarkGray}>{item.title}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography color={(theme) => theme.palette.vtbAuto.PrimaryGray}>
              {item.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </section>
  );
};
