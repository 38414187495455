import { FC, useCallback, useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';

import CarForeignMobile from '~/assets/images/car-foreign-mobile.webp';
import CarForeign from '~/assets/images/car-foreign.webp';
import CarNewMobile from '~/assets/images/car-new-mobile.webp';
import CarNew from '~/assets/images/car-new.webp';
import CarOrderMobile from '~/assets/images/car-order-mobile.webp';
import CarOrder from '~/assets/images/car-order.webp';
import CarUsedMobile from '~/assets/images/car-used-mobile.webp';
import CarUsed from '~/assets/images/car-used.webp';
import { useBreakpoints } from '~/hooks';

import { PriceCalculationExample } from './components';
import {
  AccordionImage,
  Card,
  Description,
  DetailsText,
  Image,
  Title,
} from './PurchaseTiles.styles';

export const PurchaseTiles: FC = () => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints();
  const [openCardIndex, setCardPileIndex] = useState<number | undefined>(0);

  const description = useCallback(
    (items: string[]) => (
      <Stack rowGap={{ xs: 0.5, sm: 1 }}>
        {items.map((item, index: number) => (
          <Stack direction='row' key={index} columnGap={0.5}>
            <Description>—</Description>
            <Description>{item}</Description>
          </Stack>
        ))}
      </Stack>
    ),
    [],
  );

  const items = useMemo(
    () => [
      {
        title: (
          <>
            Новые авто с отложенным основным платежом
            {(!isDesktop) && (
              <Tooltip title='Вы можете приобрести автомобиль со сниженными платежами в месяц и отложить основной на срок от 3 месяцев до 3 лет'>
                <IconButton size='small'>
                  <InfoOutlinedIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
        description:
          'Покупка со сниженными ежемесячными платежами и отложенным основным платежом до 3 лет',
        imageUrl: CarNew,
        mobileImageUrl: CarNewMobile,
        content: (
          <Stack rowGap={{ xs: 1, lg: 2 }}>
            <Title>
              {isDesktop && (
                <>
                  Новые авто с отложенным основным платежом&nbsp;
                  <Tooltip title='Вы можете приобрести автомобиль со сниженными платежами в месяц и отложить основной на срок от 3 месяцев до 3 лет'>
                    <IconButton size='small'>
                      <InfoOutlinedIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Title>
            {description([
              'Вы найдете нужный авто: в наличии или под заказ',
              'Сниженные фиксированные цены. Без скрытых платежей и комиссий',
              'Возможность автокредитования на специальных условиях от ВТБ',
            ])}
          </Stack>
        ),
      },
      {
        title: 'В наличии из-за рубежа',
        description: 'Европейские, японские и другие зарубежные марки на выгодных условиях',
        imageUrl: CarForeign,
        mobileImageUrl: CarForeignMobile,
        content: (
          <Stack rowGap={{ xs: 1, lg: 2 }}>
            <Title>Авто популярных зарубежных марок уже в наличии в собственном парке</Title>
            {description([
              'Все автомобили растаможены и привезены в соответствии с требованиями законодательства Российской Федерации',
              'Никаких скрытых платежей и комиссий',
              'Простая и безопасная сделка',
              'Автокредитование на специальных условиях',
              'Бесплатная доставка авто до дома',
            ])}
          </Stack>
        ),
      },
      {
        title: 'С пробегом',
        description: `Большой выбор популярных марок`,
        imageUrl: CarUsed,
        mobileImageUrl: CarUsedMobile,
        content: (
          <Stack rowGap={{ xs: 1, lg: 2 }}>
            <Title>Собственный автопарк надежных и проверенных авто</Title>
            {description([
              '100% прозрачная история авто с проверкой по VIN-коду',
              'Все авто куплены и обслуживались у официальных дилеров',
              'Полностью проверенный автомобиль с гарантией отличного состояния',
            ])}
          </Stack>
        ),
      },
      {
        title: 'Под заказ',
        description: `Нужное вам авто напрямую от производителя`,
        imageUrl: CarOrder,
        mobileImageUrl: CarOrderMobile,
        content: (
          <Stack rowGap={{ xs: 1, lg: 2 }}>
            <Title>Привезем авто по индивидуальному запросу</Title>
            {description([
              'Специальные цены от официальных производителей',
              'Экспресс-доставка',
              'Официальная гарантия от производителя',
            ])}
          </Stack>
        ),
      },
    ],
    [isDesktop],
  );

  const openedItem = typeof openCardIndex === 'number' ? items[openCardIndex] : undefined;

  return (
    <section>
      {isDesktop && (
        <>
          <Grid container spacing={4} alignItems='stretch' mb={4}>
            {items.map((item, index) => {
              const isOpen = index === openCardIndex;

              return (
                <Grid item lg={3} key={index}>
                  <Card
                    isOpen={isOpen}
                    padding={3}
                    rowGap={2}
                    borderRadius={2}
                    justifyContent='space-between'
                    onClick={() => setCardPileIndex(isOpen ? undefined : index)}
                  >
                    <Stack rowGap={1}>
                      <Title>{item.title}</Title>
                      <Description>{item.description}</Description>
                    </Stack>

                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                      <DetailsText>Подробнее</DetailsText>
                      <DetailsText>{isOpen ? '↓' : '→'}</DetailsText>
                    </Stack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {openedItem && (
            <Grid container spacing={4}>
              <Grid item lg={4}>
                <Stack justifyContent='center' alignItems='center' height='100%'>
                  <Image src={openedItem.imageUrl} />
                </Stack>
              </Grid>
              <Grid item lg={8}>
                <Stack justifyContent='center' height='100%'>
                  {openedItem.content}
                </Stack>
              </Grid>
            </Grid>
          )}

          {openCardIndex === 0 && (
            <Box marginTop={4}>
              <PriceCalculationExample />
            </Box>
          )}
        </>
      )}

      {(isTablet || isMobile) && (
        <Stack rowGap={1}>
          {items.map((item, index) => (
            <Accordion
              variant='round'
              key={index}
              elevation={0}
              disableGutters
              square
              defaultExpanded={index === 0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction='row' alignItems='center' columnGap={1}>
                  {isMobile && <AccordionImage src={item.mobileImageUrl} alt='Purchase image' />}
                  <Title>{item.title}</Title>
                </Stack>
              </AccordionSummary>

              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Description>{item.description}</Description>
                  </Grid>

                  {!isMobile && (
                    <Grid item xs={12} sm={6}>
                      <Stack justifyContent='top' alignItems='center' height='100%'>
                        <Image src={item.imageUrl} />
                      </Stack>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    {item.content}
                  </Grid>

                  {index === 0 && (
                    <Grid item xs={12}>
                      <PriceCalculationExample />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      )}
    </section>
  );
};
