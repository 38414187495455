import {
  ComponentProps,
  MouseEvent,
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import type { Settings } from 'react-slick';
import SlickSlider from 'react-slick';

import { SliderStyling } from './Slider.styles';

const DEFAULT_SETTINGS: Settings = {
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
};

type Props = PropsWithChildren<
  {
    sx?: ComponentProps<typeof SliderStyling>['sx'];
    className?: string;
    enableMouseMoveSlide?: boolean;
  } & ComponentProps<typeof SlickSlider>
>;

export const Slider = forwardRef<SlickSlider, Props>(
  ({ children, sx, className, enableMouseMoveSlide = false, ...props }, outerRef) => {
    const innerRef = useRef<SlickSlider | null>(null);

    useImperativeHandle(outerRef, () => innerRef.current!, []);

    /** Переключает слайды при движении мыши */
    function handleMouseMove(event: MouseEvent) {
      const slidesCount = Array.isArray(children) ? children.length : 0;

      if (!enableMouseMoveSlide || slidesCount === 0) {
        return;
      }

      const targetRect = event.currentTarget.getBoundingClientRect();
      const diff = targetRect.width / slidesCount;
      const leftBorder = targetRect.left;

      for (let i = 0; i < slidesCount; i++) {
        // Проверяем в какой зоне оказалась мышь и переключаем слайд на индекс зоны
        if (leftBorder + diff * i < event.pageX && leftBorder + diff * (i + 1) > event.pageX) {
          innerRef.current?.slickGoTo(i);
          break;
        }
      }
    }

    function handleMouseLeave(event: MouseEvent) {
      const slidesCount = Array.isArray(children) ? children.length : 0;

      if (!enableMouseMoveSlide || slidesCount === 0) {
        return;
      }
      // Reset slider index on mouse leave
      innerRef.current?.slickGoTo(0);
    }

    return (
      <SliderStyling
        className={className}
        sx={sx}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <SlickSlider
          dots={Array.isArray(children) ? children.length > 1 : false}
          {...DEFAULT_SETTINGS}
          ref={innerRef}
          {...props}
        >
          {children}
        </SlickSlider>
      </SliderStyling>
    );
  },
);
