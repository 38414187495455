import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { TCar, TCarBrand, TFeedbackConsultation, TFeedbackRequest } from 'types';

import { DEFAULT_FILTERS } from '~/consts';
import type { EParamKeys } from '~/types';

import { interceptor } from './interceptor';

/** https://wiki.vtb-leasing.com/pages/viewpage.action?pageId=219317540 */
class API {
  http: AxiosInstance;

  constructor() {
    this.http = axios.create({ baseURL: `${process.env.BFF_URL}/api`, withCredentials: true });
    this.http.interceptors.request.use(interceptor);
  }

  getTimestamp() {
    return this.http.get<{ timestamp: number }>('/timestamp').then(({ data }) => data);
  }

  sendCallbackRequest(feedbackRequest: TFeedbackRequest) {
    return this.http.post('/feedback/request', feedbackRequest).then((data) => data);
  }

  sendCallbackConsultation(feedbackConsultation: TFeedbackConsultation) {
    return this.http.post('/feedback/consultation', feedbackConsultation).then((data) => data);
  }

  getAllTags() {
    return this.http
      .get<TCar['newTags']>('/catalog/cars/field_list/newTags')
      .then(({ data }) => data);
  }

  getAllBrandsAndModels({
    newTags,
    isSubscription,
  }: {
    newTags?: string[];
    isSubscription: boolean;
  }) {
    return this.http
      .get<TCarBrand[]>('/catalog/brands/full', {
        params: {
          newTags,
          'has[]': isSubscription ? 'for_subscription' : 'for_sale',
        },
        paramsSerializer: (params) => {
          const queries = Object.entries(params).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
              const values = value.filter(Boolean);
              values.length && acc.push(`${key}=${values.join(',')}`);
              return acc;
            }

            if (value) {
              acc.push(`${key}=${value}`);
              return acc;
            }

            return acc;
          }, [] as string[]);

          return queries.length ? queries.join('&') : '';
        },
      })
      .then(({ data }) => data);
  }

  searchCars(params: Record<(typeof EParamKeys)[keyof typeof EParamKeys], unknown>) {
    return this.http
      .post<{
        currentPage: number;
        data: TCar[];
        total: number;
        to: number;
        last_page: number;
        per_page: number;
      }>('/catalog/cars', { ...DEFAULT_FILTERS, ...params })
      .then(({ data }) => data);
  }

  getRelatedCars(carId: string, type: 'subscription' | 'purchase', tag?: string) {
    return (
      this.http
        // TODO ask form
        .get<TCar[]>(`/catalog/car/${carId}/related`, { params: { type, 'tags[]': tag } })
        .then(({ data }) => data)
    );
  }

  getCar(id: string) {
    return this.http.get<TCar>(`/catalog/car/${id}`).then(({ data }) => data);
  }
}

export const bff = new API();
