import { FC } from 'react';

import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';
import { separateThousands } from '~/utils';

import { NewPrice, OldPrice, PriceWrapper } from './Price.styles';

type Props = {
  car: TCar;
};
export const Price: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  if (isSubscription) {
    const subscription = car.subscription;
    let newPrice = subscription.price,
      oldPrice;

    if (subscription.price_spec > 0 && subscription.price_spec != subscription.price) {
      newPrice = subscription.price_spec;
      oldPrice = subscription.price;
    }

    return (
      <PriceWrapper>
        {newPrice && <NewPrice>{`от ${separateThousands(newPrice)} ₽ / мес.`}</NewPrice>}
        {oldPrice && <OldPrice>{`от ${separateThousands(oldPrice)} ₽ / мес.`}</OldPrice>}
      </PriceWrapper>
    );
  } else {
    const newPrice = car.pokupkaPrice != car.fullPrice ? car.pokupkaPrice : car.fullPrice;
    const oldPrice = car.pokupkaPrice != car.fullPrice && car.fullPrice;

    return (
      <PriceWrapper>
        {newPrice && <NewPrice>{`${separateThousands(newPrice)} ₽`}</NewPrice>}
        {oldPrice && <OldPrice>{`${separateThousands(oldPrice)} ₽`}</OldPrice>}
      </PriceWrapper>
    );
  }
};
