import { FC, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Stack, Typography } from '@mui/material';

import { CarCards } from '~/components';
import { useBreakpoints, useIsSubscription } from '~/hooks';
import { useRelatedCars } from '~/queries';
import { TCar } from '~/types';

import { RelatedCarsSkeleton } from './RelatedCars.skeleton';
import { LoadMoreButton } from './RelatedCars.styles';

const getItemsPerRow = (isDesktop: boolean) => (isDesktop ? 4 : 2);

type Props = {
  car: TCar;
  showSelfTag: boolean;
};
export const RelatedCars: FC<Props> = ({ car, showSelfTag }) => {
  const isSubscription = useIsSubscription();

  const { data: relatedCars, isValidating } = useRelatedCars({
    carId: car.id.toString(),
    carTag: car.newTags[0]?.label,
    carType: isSubscription ? 'subscription' : 'purchase',
  });
  const { isDesktop } = useBreakpoints();
  const { pathname } = useLocation();

  const [renderCount, setRenderCount] = useState(getItemsPerRow(isDesktop));

  const loadMore = () => {
    setRenderCount((prevCount) => prevCount + getItemsPerRow(isDesktop));
  };

  useLayoutEffect(() => {
    // Сброс количества карточек до значения по-умолчанию
    setRenderCount(getItemsPerRow(isDesktop));
  }, [isDesktop, pathname]);

  if (isValidating) {
    return <RelatedCarsSkeleton />;
  }

  if (!relatedCars?.length) {
    return null;
  }

  return (
    <section>
      {relatedCars && (
        <Stack>
          <Typography
            variant='h5'
            color={(theme) => theme.palette.vtbAuto.DarkGray}
            marginBottom={{ xs: 1, sm: 2, lg: 3 }}
          >
            Вам может быть интересно
          </Typography>
          <CarCards cars={relatedCars?.slice(0, renderCount)} showSelfTag={showSelfTag} isRelated />
          {relatedCars.length > renderCount && (
            <LoadMoreButton variant='contained' onClick={loadMore}>
              Загрузить еще
            </LoadMoreButton>
          )}
        </Stack>
      )}
    </section>
  );
};
