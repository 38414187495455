import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TQuerySearchParams } from '~/types';

export type Param = keyof TQuerySearchParams;

const setQueryArrayParam = (params: URLSearchParams, paramName: Param, paramValues: string[]) => {
  params.delete(paramName);

  if (paramValues?.length) {
    paramValues.forEach((value) => params.append(paramName, value));
  }

  return params;
};

const setQueryParam = (params: URLSearchParams, paramName: Param, paramValue: string) => {
  params.delete(paramName);

  if (paramValue) {
    params.set(paramName, paramValue);
  }

  return params;
};

const setQueryParams = (params: URLSearchParams, searchParams: Partial<TQuerySearchParams>) => {
  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      setQueryArrayParam(params, key as Param, value);
    } else {
      setQueryParam(params, key as Param, value);
    }
  });

  return params;
};

export const useSetQueryParams = () => {
  const [, setParams] = useSearchParams();
  return useCallback(
    (params: Partial<TQuerySearchParams>) => {
      setParams((prev) => {
        return setQueryParams(prev, params);
      });
    },
    [setParams],
  );
};
