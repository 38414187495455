import type { ThemeOptions } from '@mui/material';

import { theme } from '@vtbl/mui-kit';

export const typographyOptions: ThemeOptions = {
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '2rem', // 32px
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.25rem', // 36px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.5rem', // 40px
      },
    },
    h2: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '1.75rem', // 28px
      [theme.breakpoints.up('sm')]: {
        fontSize: '2rem', // 32px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.25rem', // 36px
      },
    },
    h3: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '1.625rem', // 26px
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.875rem', // 30px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.125rem', // 34px
      },
    },
    h4: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '1.5rem', // 24px
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.75rem', // 28px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '2rem', // 32px
      },
    },
    h5: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '1.25rem', // 20px
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.375rem', // 22px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.5rem', // 24px
      },
    },
    h6: {
      lineHeight: 1.1,
      fontWeight: 400,
      letterSpacing: '-0.5px',
      fontSize: '1rem', // 16px
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.125rem', // 18px
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem', // 20px
      },
    },
    body1: {
      lineHeight: 1.1,
    },
    body2: {
      lineHeight: 1.1,
    },
    overline: {
      textTransform: 'none',
    },
  },
};
