import type { ThemeOptions } from '@mui/material';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tag: true;
  }
}

export const chipOptions: ThemeOptions = {
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            borderRadius: '4px',
            color: 'white',
            textTransform: 'lowercase',
          },
        },
      ],
    },
  },
};
