import type { ThemeOptions } from "@mui/material";

export const checkboxOptions: ThemeOptions = {
  components: {
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
};
