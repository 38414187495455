import { FC } from 'react';

import { Button, Grid, Stack } from '@mui/material';

import { useBreakpoints, useIsSubscription } from '~/hooks';
import { routeConfig } from '~/routeConfig';
import { scrollToCallbackForm } from '~/utils';

import {
  Description,
  LinkTitle,
  Section,
  StyledContainer,
  StyledLink,
  Title,
} from './Banner.styles';

export const Banner: FC = () => {
  const { isMobile, isTablet } = useBreakpoints();
  const isSubscription = useIsSubscription();
  const purchaseTo = {
    pathname: `/${routeConfig.PurchaseCatalogue.path}`,
    search: '?page=1',
  };
  const subscriptionTo = {
    pathname: `/${routeConfig.SubscriptionCatalogue.path}`,
    search: '?page=1',
  };

  return (
    <Section isSubscription={isSubscription} isPurchase={!isSubscription} role='img'>
      <StyledContainer>
        {isMobile && (
          <Stack height='100%' justifyContent='space-between' alignItems='stretch'>
            <Stack alignItems='stretch' spacing={4}>
              <Stack direction='row' alignItems='stretch'>
                <StyledLink to={purchaseTo} left>
                  <LinkTitle variant='body1'>Покупка</LinkTitle>
                </StyledLink>
                <StyledLink to={subscriptionTo} right>
                  <LinkTitle variant='body1'>Подписка</LinkTitle>
                </StyledLink>
              </Stack>

              <Title variant='h5' textAlign='center'>
                {isSubscription ? (
                  <>
                    Современный способ владеть <br /> автомобилем, не покупая его
                  </>
                ) : (
                  <>
                    Разные варианты покупки <br /> в наличии и под заказ
                  </>
                )}
              </Title>
            </Stack>

            <Button
              variant='contained'
              color='secondary'
              size='large'
              onClick={scrollToCallbackForm}
            >
              Оставить заявку
            </Button>
          </Stack>
        )}

        {!isMobile && (
          <Stack height='100%' justifyContent='space-between'>
            <Stack>
              <Title variant='h1' marginBottom={1}>
                Выбирайте свой способ <br /> владеть автомобилем
              </Title>
              <Description>Онлайн-сервис с собственным парком и низкими ценами</Description>
            </Stack>

            <Grid container spacing={{ sm: 3, lg: 4 }}>
              <Grid item xs={6}>
                <StyledLink to={purchaseTo}>
                  <LinkTitle variant='h5'>Покупка</LinkTitle>
                  <Description>Разные варианты покупки авто в наличии и под заказ</Description>
                </StyledLink>
              </Grid>
              <Grid item xs={6}>
                <StyledLink to={subscriptionTo}>
                  <LinkTitle variant='h5'>Подписка</LinkTitle>
                  <Description>Современный способ владеть автомобилем, не покупая его</Description>
                </StyledLink>
              </Grid>
            </Grid>
          </Stack>
        )}
      </StyledContainer>
    </Section>
  );
};
