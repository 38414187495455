import { matchPath, useLocation } from 'react-router-dom';

import { routeConfig } from '~/routeConfig';

/** Checks if we are now in subscription path */
export const useIsSubscription = () => {
  const { pathname } = useLocation();

  return (
    Boolean(matchPath(routeConfig.SubscriptionCatalogue.path, pathname)) ||
    Boolean(matchPath(routeConfig.SubscriptionCard, pathname))
  );
};
