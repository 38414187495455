import { alpha, css, IconButton } from '@mui/material';
import { styled } from '@mui/system';

export const NextArrow = styled(IconButton)<{ size: 'small' | 'large' }>`
  background: ${({ theme }) => alpha(theme.palette.common.white, 0.3)};
  position: absolute;
  transform: translate(0, 50%);
  z-index: 1;
  right: 16px;
  display: flex;
  justify-content: center;

  ${({ size, theme }) =>
    size === 'large'
      ? css`
          width: 45px;
          height: 45px;
          top: calc(50% - 45px);

          ${theme.breakpoints.up('lg')} {
            width: 60px;
            height: 60px;
            top: calc(50% - 60px);
          }
        `
      : css`
          width: 30px;
          height: 30px;
          top: calc(50% - 30px);
          display: none;

          .slick-slider:hover & {
            display: flex;
          }

          & > svg {
            width: 20px;
            height: 20px;
          }
        `}
`;

export const PrevArrow = styled(NextArrow)`
  left: 16px;
  right: 0;

  & svg {
    transform: rotate(180deg);
  }
`;
