import CallIcon from '@mui/icons-material/Call';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Container, IconButton, Link, Stack } from '@mui/material';

import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_LABEL,
  TELEGRAM_URL,
  WHATSAPP_URL,
} from '~/consts';
import { useBreakpoints } from '~/hooks';

import { Logo } from '../Logo';
import { StyledFooter, StyledLink, StyledTypography } from './Footer.styles';

export const Footer = () => {
  const { isMobile } = useBreakpoints();

  return (
    <StyledFooter component='footer' pt={{ xs: 3, sm: 4 }} pb={10}>
      <Container>
        <Stack direction='row' justifyContent='space-between' mb={4}>
          {!isMobile && <Logo />}

          <Stack direction='row' spacing={1}>
            <IconButton color='primary' href={`tel:${SUPPORT_PHONE}`}>
              <CallIcon />
            </IconButton>

            <IconButton color='primary' href={TELEGRAM_URL}>
              <TelegramIcon />
            </IconButton>

            <IconButton color='primary' href={WHATSAPP_URL}>
              <WhatsAppIcon />
            </IconButton>
          </Stack>
        </Stack>

        <Stack spacing={3} mb={4}>
          <Stack>
            <StyledLink href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE_LABEL}</StyledLink>
            <StyledTypography>Мы на связи с 9:00 до 20:00</StyledTypography>
          </Stack>

          <Stack>
            <StyledLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</StyledLink>
            <StyledTypography>Техническая поддержка</StyledTypography>
          </Stack>
        </Stack>

        <StyledTypography>
          Услуги предоставляются ООО «УКА» (ИНН 7702827041).
          <br />
          <br />
          Обращаем ваше внимание, что вся информация, размещенная на сайте, носит информационный
          характер и не является публичной офертой, определяемой положениями статьи 437 Гражданского
          кодекса Российской Федерации. ООО «УКА» оставляет за собой право в одностороннем порядке в
          любое время без уведомления вносить изменения, удалять, исправлять, дополнять, либо иным
          способом обновлять информацию об условиях программы. Конкретные условия договора будут
          определяться отдельно при его заключении. Ни ООО «УКА», ни его сотрудники не несут
          ответственности за убытки, возникшие в результате использования информации, содержащейся
          на сайте, в инвестиционных или в иных целях, а также за прямые или косвенные убытки,
          понесенные в результате неисправностей или перерывов в работе сайта. Продавец либо
          Арендодатель: ООО «УКА», ОГРН 5137746118252, ИНН 7702827041. Входит в Группу ВТБ.
          <br />
          <br />
          Для повышения удобства работы с сайтом ООО «УКА» использует файлы Cookie. Оставаясь на
          сайте, вы соглашаетесь{' '}
          <Link
            sx={{
              color: (theme) => theme.palette.vtbAuto.BluePrimary,
              textDecoration: 'auto',
            }}
            variant='body2'
            href='/assets/files/politika-cookie.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            с Политикой использования Cookies
          </Link>
          . Если вы не хотите, чтобы Cookie-данные обрабатывались, отключите их в настройках
          браузера.
        </StyledTypography>
      </Container>
    </StyledFooter>
  );
};
