import type { ControllerProps, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { CheckboxProps, FormControlLabelProps, TypographyProps } from '@mui/material';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';

import { useBreakpoints } from '~/hooks';

export type CheckboxWithLabelControllerProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  'render'
> & {
  CheckboxProps?: CheckboxProps;
  LabelProps?: TypographyProps;
} & Pick<FormControlLabelProps, 'label'>;

export const CheckboxWithLabelController = <T extends FieldValues>({
  CheckboxProps = {},
  LabelProps = {},
  label,
  ...props
}: CheckboxWithLabelControllerProps<T>) => {
  const { isMobile } = useBreakpoints();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Controller
            {...props}
            render={({ field }) => {
              // @ts-ignore
              return (
                <Checkbox checked={field.value} {...field} {...CheckboxProps} color='default' />
              );
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
