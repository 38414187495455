import { Container } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHeader = styled('header')`
  background-color: ${({ theme }) => theme.palette.common.white};
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
  height: 74px;
`;

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
