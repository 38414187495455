import { Accordion, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;
Title.defaultProps = {
  variant: 'h6',
};

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
`;

export const Image = styled('img')`
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: ${({ theme }) => theme.spacing(6)};
  height: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ theme }) => theme.spacing(10)};
    height: ${({ theme }) => theme.spacing(10)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: ${({ theme }) => theme.spacing(12)};
    height: ${({ theme }) => theme.spacing(12)};
  }
`;