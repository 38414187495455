import { FC } from 'react';

import { Card, Catalogue, DocumentsArchive, NotFound } from '~/pages';

type TNavigationEventName =
  | 'NotFoundPage'
  | 'SubscriptionCard'
  | 'PurchaseCard'
  | 'SubscriptionCatalogue'
  | 'PurchaseCatalogue'
  | 'DocumentsArchive';

export const routeConfig: Record<
  TNavigationEventName,
  { Component: FC; path: string; name: TNavigationEventName }
> = {
  NotFoundPage: { path: '404', Component: NotFound, name: 'NotFoundPage' },
  SubscriptionCatalogue: { path: 'podpiska', Component: Catalogue, name: 'SubscriptionCatalogue' },
  PurchaseCatalogue: { path: 'pokupka', Component: Catalogue, name: 'PurchaseCatalogue' },
  PurchaseCard: { path: 'pokupka/:id', Component: Card, name: 'PurchaseCard' },
  SubscriptionCard: { path: 'podpiska/:id', Component: Card, name: 'SubscriptionCard' },
  DocumentsArchive: {
    path: 'archiv-dokumentov',
    Component: DocumentsArchive,
    name: 'DocumentsArchive',
  },
};
