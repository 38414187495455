import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import EastIcon from '@mui/icons-material/East';
import { Link, Stack, Typography } from '@mui/material';

import { useBreakpoints, useIsSubscription } from '~/hooks';
import { routeConfig } from '~/routeConfig';

import { IconWrapper, StyledButton } from './Documents.styles';

export const Documents: FC = () => {
  const { isMobile } = useBreakpoints();
  const isSubscription = useIsSubscription();

  return (
    <section>
      <Typography variant='h5' mb={2} color={(theme) => theme.palette.vtbAuto.BlueAccent}>
        {isSubscription
          ? 'Документы для оформления подписки'
          : 'Документы для оформления покупки с оплатой частями'}
      </Typography>

      <Stack direction={isMobile ? 'column' : 'row'} justifyContent='stretch' spacing={2}>
        <StyledButton
          href='/assets/files/rental-agreement-25-07-2024.pdf'
          target='_blank'
          rel='noopener noreferrer'
        >
          Договор аренды
        </StyledButton>
        <StyledButton
          href='/assets/files/rental-rules-25-07-2024.pdf'
          target='_blank'
          rel='noopener noreferrer'
        >
          Правила аренды
        </StyledButton>
      </Stack>

      <Link
        display='block'
        variant='body2'
        mt={2}
        component={RouterLink}
        sx={{
          color: (theme) => theme.palette.vtbAuto.BlueAccent,
          fill: (theme) => theme.palette.vtbAuto.BlueAccent,
        }}
        underline='none'
        to={`/${routeConfig.DocumentsArchive.path}`}
      >
        <Stack direction='row' alignItems='center' columnGap={1}>
          <span>Архив документов — правила аренды</span>
          <IconWrapper>
            <EastIcon />
          </IconWrapper>
        </Stack>
      </Link>
    </section>
  );
};
