import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Link, Typography } from '@mui/material';

import {
  CheckboxWithLabelController,
  PhoneInputControllerWithRules,
  TextFieldController,
} from '~/components';
import { SESSION_STORAGE_SUBSCRIPTION_TERM_KEY } from '~/consts';
import { useIsCatalog, useIsSubscription, useQueryStringParam } from '~/hooks';
import { bff } from '~/services';
import { ECarTagLabel, EParamKeys, TCar, TFeedbackRequest } from '~/types';
import { emailRules, nameRules } from '~/validation';

import { FormWrapper, RequestButton } from './Form.styles';

// 1 час
const DOC_CHECK_EXPIRATION_TIME = 60 * 60 * 1000;

type FormValues = {
  clientName: string;
  email?: string;
  phoneNumber: string;
  sopd: boolean;
  sopdAdv: boolean;
};

type Props = {
  car?: TCar;
  onSubmit: () => void;
};

type TDocsTimer = {
  timestamp: number;
  id: NodeJS.Timeout;
} | null;

export const Form: FC<Props> = ({ car, onSubmit }) => {
  const isCatalog = useIsCatalog();
  const isSubscription = useIsSubscription();
  const queryCarTag = useQueryStringParam(EParamKeys.Tag, '');

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sopdTimeRef = useRef<TDocsTimer>(null);
  const sopdAdvTimeRef = useRef<TDocsTimer>(null);

  const { handleSubmit, control, setValue, watch } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      clientName: '',
      phoneNumber: '',
      sopd: false,
      sopdAdv: false,
    },
    delayError: 500,
  });

  const [sopd, sopdAdv] = watch(['sopd', 'sopdAdv']);

  useEffect(() => {
    onDocValueChange('sopd', sopd, sopdTimeRef);
  }, [sopd]);

  useEffect(() => {
    onDocValueChange('sopdAdv', sopdAdv, sopdAdvTimeRef);
  }, [sopdAdv]);

  const onDocValueChange = useCallback(
    async (fieldName: keyof FormValues, checked: boolean, ref: MutableRefObject<TDocsTimer>) => {
      clearTimeout(ref.current?.id);
      ref.current = null;

      if (checked) {
        try {
          ref.current = {
            timestamp: (await bff.getTimestamp()).timestamp,
            id: setTimeout(
              () => setValue(fieldName, false, { shouldValidate: true }),
              DOC_CHECK_EXPIRATION_TIME,
            ),
          };
        } catch (e) {
          console.error(e);
        }
      }
    },
    [],
  );

  const getEventName = (car?: TCar): TFeedbackRequest['event_name'] => {
    if (isCatalog || !car) {
      return 'REQUEST_CONSULTATION';
    } else {
      if (isSubscription) {
        return 'REQUEST_CAR_SUBSCRIPTION';
      } else {
        if (car.newTags?.length > 0 && car.newTags[0].label === ECarTagLabel.Foreign) {
          return 'REQUEST_CAR_FROM_ABROAD';
        }

        if (car.newTags?.length > 0 && car.newTags[0].label === ECarTagLabel.DelayedPayment) {
          return 'REQUEST_CAR_REDEMPTION_SUBSCRIPTION';
        }

        if (car.owners_number > 0) {
          return 'REQUEST_CAR_USED';
        }

        return 'REQUEST_CONSULTATION';
      }
    }
  };

  const submitHandler = async (data: FormValues) => {
    // Prepare request body
    let requestBody: TFeedbackRequest = {
      clientName: data.clientName,
      phoneNumber: data.phoneNumber,
      email: data.email || undefined,
      sopd: data.sopd,
      sopd_time: sopdTimeRef.current?.timestamp!,
      sopd_adv_time: sopdAdvTimeRef.current?.timestamp,
      event_name: getEventName(car),
    };

    if (!isCatalog && car) {
      const carInfo = {
        unique_id: car.auto_id,
        car_name: car.mark_id,
        auto_model: car.folder_id,
        complectation: car.complectation_name,
        modification: car.modification_id,
        auto_type: car.body_type,
      };
      const subscription_term = sessionStorage.getItem(SESSION_STORAGE_SUBSCRIPTION_TERM_KEY) || '';
      const termPriceInfo = isSubscription
        ? {
            ...carInfo,
            term: JSON.parse(subscription_term).period,
            mpayment: JSON.parse(subscription_term).price,
          }
        : {
            ...carInfo,
            old_price: car.fullPrice,
            price: car.pokupkaPrice != car.fullPrice ? car.pokupkaPrice : car.fullPrice,
            fullPrice: car.fullPrice,
            mileage: car.owners_number > 0 ? `${car?.mileage}` : undefined,
          };

      requestBody = { ...requestBody, ...termPriceInfo };
    }

    // Send request
    try {
      // SOPD timer didn't send
      if (!sopdTimeRef.current) {
        throw new Error();
      }

      setIsLoading(true);
      setIsError(false);

      isCatalog
        ? await bff.sendCallbackConsultation(requestBody)
        : await bff.sendCallbackRequest(requestBody);

      onSubmit();

      // Send Yandex metricas on form submit
      ym(95133370, 'reachGoal', 'form_success');

      if (isSubscription) {
        isCatalog
          ? ym(95133370, 'reachGoal', 'subscription_form_page')
          : ym(95133370, 'reachGoal', 'subscription_form_with_card');
      } else {
        isCatalog
          ? ym(95133370, 'reachGoal', 'purchase_form_page')
          : ym(95133370, 'reachGoal', 'form_purchase_with_card');

        const carTag = queryCarTag || car?.newTags[0].label;

        if (carTag === ECarTagLabel.WithMileage) {
          isCatalog
            ? ym(95133370, 'reachGoal', 'form_used_car_page')
            : ym(95133370, 'reachGoal', 'form_used_car_with_card');
        } else if (carTag === ECarTagLabel.DelayedPayment) {
          isCatalog
            ? ym(95133370, 'reachGoal', 'form_payment_in_parts_page')
            : ym(95133370, 'reachGoal', 'form_payment_in_parts_with_card');
        } else if (carTag === ECarTagLabel.Foreign) {
          isCatalog
            ? ym(95133370, 'reachGoal', 'form_import_page')
            : ym(95133370, 'reachGoal', 'form_import_with_card');
        }
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit(submitHandler)}>
      <TextFieldController
        name='clientName'
        control={control}
        rules={nameRules}
        fieldProps={{ placeholder: 'ФИО', size: 'medium' }}
      />
      <PhoneInputControllerWithRules
        name='phoneNumber'
        control={control}
        MaskedTextFieldProps={{
          placeholder: '+7 (000) 000-00-00',
          size: 'medium',
        }}
      />
      <TextFieldController
        name='email'
        control={control}
        rules={emailRules}
        fieldProps={{
          placeholder: 'E-mail (необязательно)',
          size: 'medium',
        }}
      />
      <CheckboxWithLabelController
        name='sopd'
        control={control}
        rules={{ required: true }}
        label={
          <Typography variant='body2' color={(theme) => theme.palette.common.white}>
            <Link
              href='/assets/files/sopd-04-06-24.pdf'
              target='_blank'
              rel='noopener noreferrer'
              underline='hover'
              color='inherit'
            >
              Согласен на обработку персональных данных.
            </Link>{' '}
            <Link
              href='/assets/files/politika.pdf'
              target='_blank'
              rel='noopener noreferrer'
              underline='hover'
              color='inherit'
            >
              Политика обработки персональных данных.
            </Link>
          </Typography>
        }
        CheckboxProps={{ sx: { color: 'white' } }}
      />
      <CheckboxWithLabelController
        name='sopdAdv'
        control={control}
        label={
          <Typography variant='body2' color={(theme) => theme.palette.common.white}>
            <Link
              href='/assets/files/adv-agreement.pdf'
              target='_blank'
              rel='noopener noreferrer'
              underline='hover'
              color='inherit'
            >
              Согласен на получение информации о продуктах и услугах
            </Link>{' '}
            ООО «УКА» и{' '}
            <Link
              href='/assets/files/adv-sopd.pdf'
              target='_blank'
              rel='noopener noreferrer'
              underline='hover'
              color='inherit'
            >
              обработку персональных данных.
            </Link>
          </Typography>
        }
        CheckboxProps={{ sx: { color: 'white' } }}
      />
      <RequestButton
        variant='contained'
        color='secondary'
        size='large'
        type='submit'
        onClick={handleSubmit(submitHandler)}
        loading={isLoading}
      >
        Оставить заявку
      </RequestButton>

      {isError && (
        <Typography variant='body2' color={(theme) => theme.palette.vtbl.ThimbleberrySmart}>
          Не удалось отправить ваше сообщение. Попробуйте отправить позже.
        </Typography>
      )}
    </FormWrapper>
  );
};
