import { createTheme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';

import { paletteOptions } from '~/theme/options/palette';

import {
  accordionOptions,
  autocompleteOptions,
  buttonOptions,
  checkboxOptions,
  chipOptions,
  linkOptions,
  selectOptions,
  textFieldOptions,
  tooltipOptions,
  typographyOptions,
} from './options';

let themeOptions = [
  accordionOptions,
  autocompleteOptions,
  buttonOptions,
  checkboxOptions,
  chipOptions,
  linkOptions,
  paletteOptions,
  selectOptions,
  textFieldOptions,
  tooltipOptions,
  typographyOptions,
].reduce((acc, option) => {
  return deepmerge(acc, option);
}, {} as ThemeOptions);

themeOptions = deepmerge(
  {
    shape: {
      borderRadius: 8,
    },
  },
  themeOptions,
);

export const theme = createTheme(themeOptions);
