import { darken, lighten } from '@mui/material';

import { VtblColors } from '@vtbl/mui-kit';

import { VtbAutoColors } from '~/theme/colors';

declare module '@mui/material/styles' {
  interface Palette {
    // TODO remove vtbAuto, vtbl
    vtbAuto: typeof VtbAutoColors;
    vtbl: typeof VtblColors;
    abroad: Palette['primary'];
    mileage: Palette['primary'];
    gradients: typeof paletteOptions.palette.gradients;
  }

  interface PaletteOptions {
    // TODO remove vtbAuto, vtbl
    vtbAuto?: typeof VtbAutoColors;
    vtbl?: typeof VtblColors;
    abroad?: PaletteOptions['primary'];
    mileage?: PaletteOptions['primary'];
    gradients: typeof paletteOptions.palette.gradients;
  }
}

const abroadMain = '#900258';
const abroadLight = lighten(abroadMain, 0.8);
const abroadDark = darken(abroadMain, 0.8);
const mileageMain = '#018296';
const mileageLight = lighten(mileageMain, 0.8);
const mileageDark = darken(mileageMain, 0.8);

export const paletteOptions = {
  palette: {
    primary: {
      main: '#0056FF',
    },
    secondary: {
      main: '#2F3441',
    },
    background: {
      default: '#F4F7F9',
      paper: '#ffffff',
    },
    text: {
      primary: '#2F3441',
      secondary: '#6b7683',
      disabled: '#b0bec5',
    },
    abroad: {
      main: abroadMain,
      light: abroadLight,
      dark: abroadDark,
      contrastText: '#FFFFFF',
    },
    mileage: {
      main: mileageMain,
      light: mileageLight,
      dark: mileageDark,
      contrastText: '#FFFFFF',
    },
    gradients: {
      primary: `linear-gradient(to right, ${lighten('#0056FF', 0.8)}, ${darken('#0056FF', 0.8)})`,
      abroad: `linear-gradient(to right, ${abroadLight}, ${abroadDark})`,
      mileage: `linear-gradient(to right, ${mileageLight}, ${mileageDark})`,
    },
    // TODO remove vtbAuto, vtbl
    vtbAuto: VtbAutoColors,
    vtbl: VtblColors,
  },
};
