import { FC } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';

import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';
import { scrollToCallbackForm } from '~/utils';

import { CarInfoSkeleton } from './CarInfo.skeleton';
import { PurchaseInfo, SubscriptionInfo } from './components';

type Props = {
  car?: TCar;
};
export const CarInfo: FC<Props> = ({ car }) => {
  const isSubscription = useIsSubscription();

  if (!car) {
    return <CarInfoSkeleton />;
  }

  return (
    <Stack justifyContent='space-between' rowGap={{ xs: 2, lg: 4 }}>
      <Box>
        <Typography variant='h4' color={(theme) => theme.palette.vtbAuto.DarkGray} marginBottom={1}>
          {`${car.name} ${car.complectation_name}`}
        </Typography>
        {isSubscription ? <SubscriptionInfo car={car} /> : <PurchaseInfo car={car} />}
      </Box>

      <Button variant='contained' size='large' fullWidth onClick={scrollToCallbackForm}>
        Оставить заявку
      </Button>
    </Stack>
  );
};
