import { styled } from '@mui/system';

export const Main = styled('main')`
  background: ${({ theme }) => theme.palette.vtbl.WhiteSmoke};
  // Need for windows bottom scroll
  overflow-x: hidden;
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
