import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link } from '@mui/material';

import { routeConfig } from '~/routeConfig';
import { SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY } from '~/consts';
import { useIsSubscription } from '~/hooks';

export const GoToCatalogueLInk: FC = () => {
  const isSubscription = useIsSubscription();
  const catalogueQuery = sessionStorage.getItem(SESSION_STORAGE_CATALOGUE_SEARCH_PARAMS_KEY) || '';

  return (
    <Link
      component={RouterLink}
      to={`/${
        isSubscription ? routeConfig.SubscriptionCatalogue.path : routeConfig.PurchaseCatalogue.path
      }${catalogueQuery}`}
      mt={2}
      mb={-2}
      color={(theme) => theme.palette.vtbAuto.BlueAccent}
      variant='body2'
      display='block'
      underline='none'
      zIndex={1}
    >
      В каталог →
    </Link>
  );
};
