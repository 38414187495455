import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/system';

export const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 405px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 420px;
  }
`;

export const RequestButton = styled(LoadingButton)`
  && {
    .MuiLoadingButton-loadingIndicator {
      position: static;
      margin-right: 8px;
    }

    .MuiCircularProgress-root {
      width: 24px;
      height: 24px;
    }
  }
`;
