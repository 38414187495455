import { useSearchParams } from 'react-router-dom';

import useSWR from 'swr';

import { DEFAULT_FILTERS } from '~/consts';
import { useIsSubscription } from '~/hooks';
import { bff } from '~/services';
import { EParamKeys } from '~/types';

export const useSearchCars = () => {
  const [urlSearchParams] = useSearchParams();
  const isSubscription = useIsSubscription();
  const params = {} as Record<EParamKeys, unknown>;

  for (const [key, value] of urlSearchParams) {
    if (!(key in DEFAULT_FILTERS) || !value) {
      continue;
    }

    if (params[key]) {
      params[key] = [params[key], value].flat();
    } else {
      params[key] = value;
    }
  }

  [EParamKeys.Brands, EParamKeys.Models].forEach((key) => {
    if (key in params) {
      params[key] = [params[key]].flat();
    }
  });

  return useSWR(
    [
      'searchCars',
      {
        ...params,
        newTags: params.tags && [params.tags].flat(),
        ...(isSubscription ? { for_subscription: true } : { for_sale: true }),
        ...(params.sort_direction ? { sort_by: isSubscription ? 'commonPrice' : 'price' } : {}),
      },
    ],
    ([, params]) => bff.searchCars(params),
  );
};
