import { Accordion, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { css } from '@emotion/react';

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;
Title.defaultProps = {
  variant: 'h6',
};

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
`;

export const Card = styled(Stack)<{ isOpen?: boolean }>`
  flex: 1 0 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  height: 100%;

  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      outline: 1px solid ${theme.palette.vtbAuto.BluePrimary};
    `}
`;

export const DetailsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;

export const Image = styled('img')`
  max-height: 320px;
  max-width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

export const AccordionImage = styled('img')`
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: ${({ theme }) => theme.spacing(6)};
  height: ${({ theme }) => theme.spacing(6)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ theme }) => theme.spacing(10)};
    height: ${({ theme }) => theme.spacing(10)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: ${({ theme }) => theme.spacing(12)};
    height: ${({ theme }) => theme.spacing(12)};
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 12px !important;
    padding: 4px 0;
    border: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.palette.common.white};

    &::before {
      display: none;
    }
  }
`;
