import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledFooter = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbl.Grey};
`;
StyledTypography.defaultProps = {
  variant: 'body2',
};

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
  text-decoration: none;
`;
StyledLink.defaultProps = {
  variant: 'h5',
};
