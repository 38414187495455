import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const LoadMoreButton = styled(Button)`
  margin-top: 16px;

  &:not(:hover, :focus) {
    color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};
  }

  && {
    background: ${({ theme }) => theme.palette.vtbl.GreyBlue};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 16px auto auto;
    width: 230px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 342px;
    height: 48px;
    margin-top: 28px;
  }
`;
