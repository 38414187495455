import { FC } from 'react';

import { Box } from '@mui/material';

import { NotFound as NotFoundComponent } from '~/components';
import { routeConfig } from '~/routeConfig';

export const NotFound: FC = () => {
  return (
    <Box marginBottom={{ sx: 16, lg: 4 }} marginTop={{ sx: 16, lg: 4 }}>
      <NotFoundComponent
        title='Похоже, такой страницы не существует'
        buttonText='Перейти в каталог'
        buttonHref={`/${routeConfig.PurchaseCatalogue.path}`}
      />
    </Box>
  );
};
