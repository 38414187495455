import { Fragment, useMemo } from 'react';

import { Grid } from '@mui/material';

import { TCar } from '~/types';

import { CarCardsSkeleton } from './CarCards.skeleton';
import { CarCard, RequestCarCard } from './components';

export type CardsProps = {
  cars?: TCar[];
  isLoading?: boolean;
  isRelated?: boolean;
  showRequestCard?: boolean;
  tagToShow?: string;
  showSelfTag?: boolean;
};

export const CarCards = ({
  cars = [],
  isLoading,
  isRelated = false,
  showRequestCard = false,
  tagToShow,
  showSelfTag,
}: CardsProps) => {
  /** Определяет индекс мини-карточки ТС, после которой рисовать CarRequestCard для десктопа */
  const carRequestCardIdxRender = useMemo(() => {
    if (!showRequestCard) {
      return -1;
    }

    // Если карточки хотя бы в 3 колонки, то после карточки с индексом 10
    if (cars.length > 11) {
      return 10;
    }
    // Если карточки хотя бы в 2 колонки, то после карточки с индексом 6
    if (cars.length > 7 && cars.length < 11) {
      return 6;
    }

    // После последней карточки
    return cars.length - 1;
  }, [cars, showRequestCard]);

  return (
    <Grid container spacing={{ xs: 2, sm: 3, lg: 4 }}>
      {isLoading && <CarCardsSkeleton isRelated={isRelated} />}
      {Array.isArray(cars) &&
        cars.map((car, idx) => (
          <Fragment key={car.id}>
            <Grid item xs={isRelated ? 6 : 12} sm={6} lg={3}>
              <CarCard
                car={car}
                tagToShow={tagToShow}
                showSelfTag={showSelfTag}
                isRelated={isRelated}
              />
            </Grid>

            {carRequestCardIdxRender === idx && (
              <Grid item xs={12} sm={6} lg={3} key='request-car'>
                <RequestCarCard />
              </Grid>
            )}
          </Fragment>
        ))}
    </Grid>
  );
};
