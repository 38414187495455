import { FC, useState } from 'react';

import CarNoPhoto from '~/assets/images/car-no-photo.webp';

import { SlideImage } from './SlideImageWrapper.styles';

type Props = {
  src: string;
};
export const SlideImageWrapper: FC<Props> = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = (e) => {
    e.stopPropagation();
    setImgSrc(CarNoPhoto);
  };

  return (
    <SlideImage src={imgSrc} onError={handleError} width={imgSrc !== src ? '100%' : undefined} />
  );
};
