import { FC, StrictMode, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import { SnackbarProvider } from 'notistack';
import { SWRConfig } from 'swr';

import { Layout } from '~/components';
import { theme } from '~/theme';
import { getNonceValue } from '~/utils';

import { Routing } from './Routing';

export const App: FC = () => {
  const cache = useMemo(
    () =>
      createCache({
        key: 'vtb-auto',
        nonce: getNonceValue(),
        prepend: true,
      }),
    [],
  );

  return (
    <StrictMode>
      <CacheProvider value={cache}>
        {/* Add theme */}
        <ThemeProvider theme={theme}>
          {/* normalize.css from MUI */}
          <CssBaseline />
          {/* notistack snackbar provider */}
          <SnackbarProvider
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <SWRConfig value={{ shouldRetryOnError: false, revalidateOnFocus: false }}>
                <Layout>
                  <Routing />
                </Layout>
              </SWRConfig>
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </StrictMode>
  );
};
