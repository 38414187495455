import { useCallback } from 'react';

import { enqueueSnackbar } from 'notistack';

/**
 * Hook to get util function
 * @returns copy to clipboad function
 */
export const useCopyToClipboard = () => {
  return useCallback(async (text: string, message: string) => {
    try {
      await navigator.clipboard.writeText(text);
      enqueueSnackbar(message, { hideIconVariant: true, variant: 'success' });
    } catch (error) {
      console.log(error);
    }
  }, []);
};
