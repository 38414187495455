import { Button } from '@mui/material';
import { styled } from '@mui/system';

import { TCarTagColor } from '~/types';

export const StyledTag = styled(Button)<{ colorToken: TCarTagColor }>`
  && {
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.5px;
    padding: 16px;

    &.MuiButton-containedPrimary {
      background: ${({ theme, colorToken }) => {
        switch (colorToken) {
          case 'blue':
            return theme.palette.vtbAuto.GradientPrimaryBlueViolet;
          case 'green':
            return theme.palette.vtbAuto.GradientGreen;
          case 'purple':
            return theme.palette.vtbAuto.GradientPurple;
        }
      }} !important;
    }

    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      padding: 6px 10px;
    }
  }
`;
