import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Card = styled(Stack)`
  padding: 24px 32px 18px 32px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.BlueAccent};
`;
Title.defaultProps = {
  variant: 'h6',
};

export const Text = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    letter-spacing: -0.5px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
`;

export const TextSmall = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.75px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.75px;
  }
`;

export const PriceSmall = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const PriceBig = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.75px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: -1px;
  }
`;

export const PriceLabel = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.75px;
  }
`;

export const DescriptionLabel = styled(Typography)<{ pale?: boolean }>`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.5px;
  color: ${({ theme, pale }) =>
    pale ? theme.palette.grey[400] : theme.palette.vtbAuto.PrimaryGray};
`;

export const TextLabel = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.vtbAuto.DarkGray};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.75px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }
`;

export const IconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 19px;
  height: 19px;
  fill: ${({ theme }) => theme.palette.vtbAuto.BluePrimary};

  & svg {
    width: 19px;
    height: 19px;
  }
`;
