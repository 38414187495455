export const MOBILE_PHONE_REGEX = '^[0-9]{11}$';

export const VALIDATION_MESSAGES = {
  required: 'Обязательное поле для ввода',
  maxLength: (value: string | number) => `Допускается не более ${value} символов`,
  minLength: (value: string | number) => `Введите от ${value} символов`,
  onlyCyrillic: 'Допустимые символы: русские буквы, пробел, дефис',
  phoneLength: 'Введите номер полностью',
  email: 'Введите корректный email',
};

export const required = {
  value: true,
  message: VALIDATION_MESSAGES.required,
};
export const maxLength = (value: number) => ({
  value,
  message: VALIDATION_MESSAGES.maxLength(value),
});

export const minLength = (value: number) => ({
  value,
  message: VALIDATION_MESSAGES.minLength(value),
});

export const validateMinLength = (value: number) => (fieldValue) =>
  fieldValue.trim().length >= value || VALIDATION_MESSAGES.minLength(value);

export const validateMinLengthIfExists = (value: number) => (fieldValue) =>
  !fieldValue ? true : fieldValue.trim().length >= value || VALIDATION_MESSAGES.minLength(value);

export const minPhoneLength = {
  value: 11,
  message: VALIDATION_MESSAGES.phoneLength,
};

export const mobilePhonePattern = {
  value: new RegExp(MOBILE_PHONE_REGEX),
  message: VALIDATION_MESSAGES.phoneLength,
};

export const onlyRussianPattern = {
  value: new RegExp('^[а-яёА-ЯЁ\\s-]*$'),
  message: VALIDATION_MESSAGES.onlyCyrillic,
};

export const emailPattern = {
  value: new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
  message: VALIDATION_MESSAGES.email,
};
