import { FC } from 'react';

import { Box } from '@mui/material';

import { NotFound } from '~/components';

export const NoCarCards: FC = () => {
  return (
    <Box my={{ xs: 4, lg: 8 }}>
      <NotFound
        title='По вашему запросу ничего не нашлось'
        description='Попробуйте повторить поиск.'
      />
    </Box>
  );
};
