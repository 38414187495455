import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { CallbackForm, Documents, Faq, Seo } from '~/components';
import { useBreakpoints, useIsSubscription } from '~/hooks';
import { useCar } from '~/queries';
import { routeConfig } from '~/routeConfig';
import { ECarTagLabel } from '~/types';

import { CarDetails, CarProperties, GoToCatalogueLInk, RelatedCars } from './components';

export const Card: FC = () => {
  const { id } = useParams();
  const { isDesktop } = useBreakpoints();
  const { data: car, error } = useCar(id);
  const navigate = useNavigate();
  const isSubscription = useIsSubscription();

  if (
    (car && (isSubscription ? !car.for_subscription : !car.for_sale)) ||
    error?.response.status === 404
  ) {
    navigate('/' + routeConfig.NotFoundPage.path);
  }

  const title = isSubscription ? (
    <>
      Оставьте заявку
      {isDesktop ? <br /> : ''} на авто по подписке
    </>
  ) : (
    <>
      От вас – заявка,
      <br />
      от нас – выгодные условия!
    </>
  );

  return (
    <Stack rowGap={{ xs: 2, sm: 3, lg: 4 }} mb={{ xs: 2, sm: 3, lg: 4 }}>
      <Seo car={car} />
      <Stack component='section' rowGap={{ xs: 2, sm: 3, lg: 4 }}>
        {isDesktop && <GoToCatalogueLInk />}
        <CarDetails car={car} />
        <CarProperties car={car} />
      </Stack>

      <CallbackForm car={car} title={title} />
      {car && <RelatedCars car={car} showSelfTag={!isSubscription} />}

      {/* Отображаем документы и FAQ всегда для подписки и для покупки с тегом "Отложенный платеж" */}
      {isSubscription ||
        (car?.newTags.some((tag) => tag.label === ECarTagLabel.DelayedPayment) && (
          <>
            <Documents />
            <Faq />
          </>
        ))}
    </Stack>
  );
};
