import { FC } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import CarHidden from '~/assets/images/car-hidden.webp';
import { scrollToCallbackForm } from '~/utils';

export const RequestCarCard: FC = () => {
  return (
    <Stack
      justifyContent='space-between'
      height='100%'
      padding={2}
      borderRadius={1}
      sx={{ backgroundColor: '#96aaff' }}
    >
      <img src={CarHidden} width='100%' alt='No image' />

      <Stack rowGap={3}>
        <Stack rowGap={1}>
          <Typography variant='h6' fontWeight={500} color={(theme) => theme.palette.common.white}>
            Авто под заказ
          </Typography>
          <Typography color={(theme) => theme.palette.common.white}>
            Не нашли подходящее авто? Мы подберем для вас необходимую марку и модель
          </Typography>
        </Stack>

        <Button color='secondary' variant='outlined' onClick={scrollToCallbackForm}>
          Оставить заявку
        </Button>
      </Stack>
    </Stack>
  );
};
