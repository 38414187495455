import { CarTag } from '~/components';
import { useIsSubscription } from '~/hooks';
import { TCar } from '~/types';

import { TagsContainer } from './SliderTags.styles';

export const SliderTags = ({ car }: { car: TCar }) => {
  const isSubscription = useIsSubscription();
  const tag = car.newTags[0];

  return (
    <TagsContainer>
      {isSubscription ? (
        <CarTag label='В подписку' size='medium' colorToken='blue' />
      ) : tag ? (
        <CarTag label={tag.label} size='medium' colorToken={tag.color} />
      ) : null}
      {car.count === 0 && <CarTag size='medium' label='Нет в наличии' colorToken='black' />}
      {car.special_offer && <CarTag label='🔥' isSpecialOffer size='medium' colorToken='white' />}
    </TagsContainer>
  );
};
