import { FC } from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { StyledButton } from '~/components/Documents/Documents.styles';

const DOCUMENT_GROUPS = [
  {
    title: 'Правила аренды',
    documents: [
      {
        name: 'Правила аренды от 08.12.2023',
        url: '/assets/files/rental-rules-08-12-2023.pdf',
      },
      {
        name: 'Правила аренды от 16.08.2022',
        url: '/assets/files/rental-rules-16-08-2022.pdf',
      },
      {
        name: 'Правила аренды от 27.06.2022',
        url: '/assets/files/rental-rules-27-06-2022.pdf',
      },
      {
        name: 'Правила аренды от 01.04.2021',
        url: '/assets/files/rental-rules-01-04-2021.pdf',
      },
    ],
  },
];

export const DocumentsArchive: FC = () => {
  return (
    <section>
      <Typography variant='h5' my={4} color={(theme) => theme.palette.vtbAuto.BlueAccent}>
        Архив документов
      </Typography>

      <Grid container mb={4} spacing={4}>
        {DOCUMENT_GROUPS.map((group, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <Stack rowGap={2}>
              <Typography variant='h6' color={(theme) => theme.palette.vtbAuto.BlueAccent}>
                {group.title}
              </Typography>
              <Stack rowGap={1} alignItems="flex-start">
                {group.documents.map((document, index) => (
                  <StyledButton
                    href={document.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    key={index}
                    sx={{
                      color: (theme) => theme.palette.vtbAuto.BlueAccent,
                      borderColor: (theme) => theme.palette.vtbAuto.PrimaryGray,
                    }}
                  >
                    {document.name}
                  </StyledButton>
                ))}
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};
