import { Box } from '@mui/material';
import { styled } from '@mui/system';

import SlickCarouselThemeStyles from 'slick-carousel/slick/slick-theme.css';
import SlickCarouselStyles from 'slick-carousel/slick/slick.css';

export const SliderStyling = styled(Box)`
  ${SlickCarouselStyles}
  ${SlickCarouselThemeStyles}
  .slick-dots {
    bottom: 8px;

    & li button::before {
      font-size: 10px;
      color: ${({ theme }) => theme.palette.vtbAuto.HawaiianSky};
    }

    & li.slick-active button::before {
      color: ${({ theme }) => theme.palette.vtbAuto.BluePrimary};
    }
  }
`;
