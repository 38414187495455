import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

export const CarPropertiesSkeleton: FC = () => {
  return (
    <Stack rowGap={{ xs: 3, lg: 4 }}>
      <Skeleton variant='rounded' height={'60px'} width={'100%'} />
      <Stack
        rowGap={'8px'}
        flexDirection={'row'}
        flexWrap={'wrap'}
        sx={{ width: { xs: '100%', lg: '750px' } }}
      >
        {Array(8)
          .fill(null)
          .map((_, idx) => (
            <Stack key={idx} width={'25%'} flexDirection={'row'}>
              <Skeleton variant='circular' width={'40px'} height={'40px'} />
              <Stack ml='8px' rowGap={'4px'}>
                <Skeleton variant='rounded' height={'16px'} width={'90px'} />
                <Skeleton variant='rounded' height={'20px'} width={'100px'} />
              </Stack>
            </Stack>
          ))}
      </Stack>
      <Skeleton variant='rounded' height={'305px'} width={'100%'} />
    </Stack>
  );
};
