import { styled, Typography } from '@mui/material';

export const Options = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.PrimaryGray};
`;

export const Price = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbAuto.AccentCobalt};
  font-weight: 500;
`;
Price.defaultProps = {
  variant: 'h1',
};

export const OldPrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.vtbl.DarkGrey};
  text-decoration: line-through;
`;
